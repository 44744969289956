import React, { PureComponent } from 'react'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'
import { Metric, AllMetricsInOrder, getSimilarityMetricsValue } from '../../../../web-apis/similarity-metrics'
import { CalculatedMetrics } from '../../../../datasets/training-task'
import { isNumber } from 'util'

type OwnProps = {
    roiNumber: number,
    roiName: string,
    visibleMetrics: { [m in Metric]: boolean },
    acceptanceCriteria?: CalculatedMetrics | undefined,
    onAcceptanceCriteriaChanged: (roiName: string, metric: Metric, value: number | undefined) => void,
}

export default class SimilarityMetricsTableRow extends PureComponent<OwnProps> {

    getNumber = (value: string): number | undefined => {
        const nr = parseFloat(value);
        return _.isNaN(nr) ? undefined : nr;
    }

    getAcceptanceValue = (metric: Metric) => {
        const { acceptanceCriteria } = this.props;
        if (!acceptanceCriteria) { return undefined; }
        const value = getSimilarityMetricsValue(metric, acceptanceCriteria);
        return isNumber(value) ? value.toString() : undefined;
    }

    handleValueChanged = (metric: Metric, event: any) => {
        const val = event.target.value;
        this.props.onAcceptanceCriteriaChanged(this.props.roiName, metric, this.getNumber(val));
    }

    render() {
        const currentVisibleMetrics = AllMetricsInOrder.filter(m => this.props.visibleMetrics[m] === true);

        return (
            <tr>
                <td>{this.props.roiNumber}</td>
                <td>{this.props.roiName}</td>
                {currentVisibleMetrics.map(m => (
                    <td key={m} className="ac-cell">
                        <FormControl type='number' step={0.01} defaultValue={this.getAcceptanceValue(m)} onChange={(evt: any) => this.handleValueChanged(m, evt)} />
                    </td>
                ))}
            </tr>
        )
    }
}
