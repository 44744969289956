//@ts-ignore
import * as dcmjs from 'dcmjs';
import { generateUid } from "./guid";
import { getDICOMDate, getDICOMTime } from "./utils";
import _ from 'lodash';


export class DicomMapAnonReal {
    real2anonUidMap: { [name: string]: string } = {};
    anon2realUidMap: { [name: string]: string } = {};

    PatientID: string = "";
    PatientName: string = "";
    PatientBirthDate: string = "";
    PatientSex: string = "";
    StudyID: string = "";
    StructureSetLabel?: string = undefined;
    isInitialized: boolean = false;

    getOrGenerateAnonymizedUid = (value: string): string => {
        if (!(value in this.real2anonUidMap)) {
            const anonUid = generateUid();
            this.anon2realUidMap[anonUid] = value;
            this.real2anonUidMap[value] = anonUid;
        }

        return this.real2anonUidMap[value];
    }

}

/**
 * Pseudonymizes given in-memory DICOM dataset (javascript object). Pseudonymization data is saved into dicomMapAnonReal.
 * This function both assigns the pseudonymized result into dicomDataset and returns it.
 */
export function anonymizeDicomDataset(dicomDataset: any, dicomMapAnonReal: DicomMapAnonReal, isRtStruct: boolean = false): any {
    // store pseudonymization data so we can link the returned structure set back to this
    // image later on
    if (!dicomMapAnonReal.isInitialized) {
        dicomMapAnonReal.PatientID = dicomDataset.PatientID;
        dicomMapAnonReal.PatientName = dicomDataset.PatientName;
        dicomMapAnonReal.PatientSex = (dicomDataset.PatientSex === undefined) ? "" : dicomDataset.PatientSex;
        dicomMapAnonReal.PatientBirthDate = (dicomDataset.PatientBirthDate === undefined) ? "" : dicomDataset.PatientBirthDate;
        dicomMapAnonReal.StudyID = (dicomDataset.StudyID === undefined) ? "" : dicomDataset.StudyID;  // may not work as intended when multiple scans are uploaded

        if (isRtStruct) {
            dicomMapAnonReal.StructureSetLabel = _.get(dicomDataset, 'StructureSetLabel', undefined);
        }

        dicomMapAnonReal.isInitialized = true;
    }

    dicomDataset.PatientID = "MV-ANON-ID";
    dicomDataset.PatientName = "MV-ANON-NAME";
    dicomDataset.PatientIdentityRemoved = "YES";
    for (const attr of ['FrameOfReferenceUID', 'StudyInstanceUID', 'SeriesInstanceUID', 'SOPInstanceUID']) {
        dicomDataset[attr] = dicomMapAnonReal.getOrGenerateAnonymizedUid(dicomDataset[attr]);
    }

    // also pseudonymize relevant rtstruct attributes (anything else will get removed)
    if (isRtStruct) {
        dicomDataset.StructureSetLabel = "MV-ANON-RTSTR";

        for (const referencedFor of dicomDataset.ReferencedFrameOfReferenceSequence) {
            referencedFor.FrameOfReferenceUID = dicomMapAnonReal.getOrGenerateAnonymizedUid(referencedFor.FrameOfReferenceUID);
            for (const referencedStudy of referencedFor.RTReferencedStudySequence) {
                for (const referencedSeries of referencedStudy.RTReferencedSeriesSequence) {
                    referencedSeries.SeriesInstanceUID = dicomMapAnonReal.getOrGenerateAnonymizedUid(referencedSeries.SeriesInstanceUID);
                    for (const referencedSlice of referencedSeries.ContourImageSequence) {
                        referencedSlice.ReferencedSOPClassUID = dicomMapAnonReal.getOrGenerateAnonymizedUid(referencedSlice.ReferencedSOPClassUID);
                        referencedSlice.ReferencedSOPInstanceUID = dicomMapAnonReal.getOrGenerateAnonymizedUid(referencedSlice.ReferencedSOPInstanceUID);
                    }
                }

            }
        }

        // no need to anonymize roi labels
        // for (const roi of originalDataset.RTROIObservationsSequence) {
        //     roi.ROIObservationLabel = dicomMapAnonReal.getOrGenerateAnonymizedUid(roi.ROIObservationLabel);
        // }
    }


    // pseudo/anonymize and retain specific values from the original dicom, any other
    // tags from the original dicom will get skipped
    const ds: { [name: string]: any } = {
        'PatientName': dicomDataset.PatientName,
        'PatientID': dicomDataset.PatientID,
        'PatientBirthDate': "",
        'PatientSex': "",

        'StudyInstanceUID': dicomDataset.StudyInstanceUID,
        'StudyDate': '',
        'StudyTime': '',
        'ReferringPhysicianName': '',
        'StudyID': '',
        'AccessionNumber': '',

        'ImageType': dicomDataset.ImageType,

        'Modality': dicomDataset.Modality,
        'SeriesInstanceUID': dicomDataset.SeriesInstanceUID,
        'SeriesNumber': '',
        'SeriesDescription': '',
        'PatientPosition': (dicomDataset.PatientPosition === undefined) ? "" : dicomDataset.PatientPosition,

        'FrameOfReferenceUID': dicomDataset.FrameOfReferenceUID,
        'PositionReferenceIndicator': (dicomDataset.PositionReferenceIndicator === undefined) ? "" : dicomDataset.PositionReferenceIndicator,

        'Manufacturer': (dicomDataset.Manufacturer === undefined) ? "" : dicomDataset.Manufacturer,
        'ManufacturerModelName': (dicomDataset.ManufacturerModelName === undefined) ? "" : dicomDataset.ManufacturerModelName,

        'InstanceNumber': (dicomDataset.InstanceNumber === undefined) ? "" : dicomDataset.InstanceNumber,
        'SamplesPerPixel': (dicomDataset.SamplesPerPixel === undefined) ? "" : dicomDataset.SamplesPerPixel,
        'PhotometricInterpretation': dicomDataset.PhotometricInterpretation,
        'Rows': dicomDataset.Rows,
        'Columns': dicomDataset.Columns,
        'BitsAllocated': dicomDataset.BitsAllocated,
        'BitsStored': dicomDataset.BitsStored,
        'HighBit': dicomDataset.HighBit,
        'PixelRepresentation': dicomDataset.PixelRepresentation,

        'ImageOrientationPatient': dicomDataset.ImageOrientationPatient,
        'ImagePositionPatient': dicomDataset.ImagePositionPatient,
        'PixelSpacing': dicomDataset.PixelSpacing,
        'SliceThickness': dicomDataset.SliceThickness,
        'SOPClassUID': dicomDataset.SOPClassUID,
        'SOPInstanceUID': dicomDataset.SOPInstanceUID,

        'PatientIdentityRemoved': dicomDataset.PatientIdentityRemoved,
        'PixelData': dicomDataset.PixelData,

        "_meta": {
            "FileMetaInformationVersion": { "Value": [{ "0": 0, "1": 1 }], "vr": "OB" },
            "ImplementationClassUID": { "Value": ["1.2.840.113819.7.1.1997.1.0"], "vr": "UI" },
            "ImplementationVersionName": { "Value": ["MVision AI Oy"], "vr": "SH" },
            "MediaStorageSOPClassUID": { "Value": [dicomDataset.SOPClassUID], "vr": "UI" },
            "MediaStorageSOPInstanceUID": { "Value": [dicomDataset.SOPInstanceUID], "vr": "UI" },
            "TransferSyntaxUID": dicomDataset._meta.TransferSyntaxUID
        },

        "_vrMap": {
            // value representation map will be filled in later
        }
    }
    if ('SpecificCharacterSet' in dicomDataset) {
        ds.SpecificCharacterSet = dicomDataset.SpecificCharacterSet
    }
    if ('PixelPaddingValue' in dicomDataset) {
        ds.PixelPaddingValue = dicomDataset.PixelPaddingValue
    }
    if ('Laterality' in dicomDataset) {
        ds.Laterality = dicomDataset.Laterality
    }
    if (ds.Modality === 'CT') {
        ds.RescaleIntercept = dicomDataset.RescaleIntercept
        ds.RescaleSlope = dicomDataset.RescaleSlope
        if ('RescaleType' in dicomDataset) {
            ds.RescaleType = dicomDataset.RescaleType
        }
        ds.KVP = ('KVP' in dicomDataset) ? dicomDataset.KVP : ""
        ds.AcquisitionNumber = ""  // ('AcquisitionNumber' in dataset) ? dataset.AcquisitionNumber : ""
    } else if (ds.Modality === 'MR') {
        ds.ScanningSequence = ('ScanningSequence' in dicomDataset) ? dicomDataset.ScanningSequence : ""
        ds.SequenceVariant = ('SequenceVariant' in dicomDataset) ? dicomDataset.SequenceVariant : ""
        ds.ScanOptions = ""  // ('ScanOptions' in originalDataset) ? originalDataset.ScanOptions : ""
        ds.MRAcquisitionType = ""  // ('MRAcquisitionType' in originalDataset) ? originalDataset.MRAcquisitionType : ""
        ds.EchoTime = ""  // ('EchoTime' in originalDataset) ? originalDataset.EchoTime : ""
        ds.EchoTrainLength = ""  // ('EchoTrainLength' in originalDataset) ? originalDataset.EchoTrainLength : ""
    }

    if (isRtStruct) {
        ds['StructureSetLabel'] = dicomDataset.StructureSetLabel;
        ds['ROIContourSequence'] = dicomDataset.ROIContourSequence;
        ds['RTROIObservationsSequence'] = dicomDataset.RTROIObservationsSequence;
        ds['ReferencedFrameOfReferenceSequence'] = dicomDataset.ReferencedFrameOfReferenceSequence;
        ds['StructureSetROISequence'] = dicomDataset.StructureSetROISequence;
    }

    // set value representations
    if (_.has(dicomDataset, '_vrMap.PixelData')) {
        ds._vrMap.PixelData = dicomDataset._vrMap.PixelData;
    }
    if (_.has(dicomDataset, '_vrMap.RescaleIntercept')) {
        ds._vrMap.RescaleIntercept = dicomDataset._vrMap.RescaleIntercept;
    }

    // overwrite original dataset
    dicomDataset = ds;

    return dicomDataset;
}

/**
 * Pseudonymizes given DICOM file (array buffer). Pseudonymization data is saved into dicomMapAnonReal.
 */
export function anonymizeDicomArrayBuffer(arrayBuffer: ArrayBuffer, dicomMapAnonReal: DicomMapAnonReal, isRtStruct: boolean = false): ArrayBuffer {


    // we should test that this particular anonymization flow ANONYMIZES and WORKS correctly before using it
    // (this function is not currently used by Guide & Verify)
    throw new Error('NOT TESTED');

    const original = dcmjs.data.DicomMessage.readFile(arrayBuffer);

    // convert original DICOM file into a naturalized dataset for easy reference
    let dicomDataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(original.dict);
    dicomDataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(original.meta);

    const anonymizedDataset = anonymizeDicomDataset(dicomDataset, dicomMapAnonReal, isRtStruct);

    // convert anonymized dataset object to a dcmjs object
    const anonymized = dcmjs.data.datasetToDict(anonymizedDataset);

    // de-naturalize the core dictionary entries
    anonymized.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(anonymizedDataset);

    // write and return the dicom file
    return anonymized.write();
}

export function unAnonymizeRtstruct(arrayBuffer: ArrayBuffer, dicomMapAnonReal: DicomMapAnonReal): ArrayBuffer {
    let dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    let ds = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomDict.dict);
    ds._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomDict.meta);
    ds.PatientName = dicomMapAnonReal.PatientName
    ds.PatientID = dicomMapAnonReal.PatientID
    ds.PatientBirthDate = dicomMapAnonReal.PatientBirthDate
    ds.PatientSex = dicomMapAnonReal.PatientSex
    ds.StudyID = dicomMapAnonReal.StudyID
    ds.StudyInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.StudyInstanceUID]
    ds.StructureSetDate = getDICOMDate();
    ds.StructureSetTime = getDICOMTime();

    ds.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID]
    ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID]
    ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.ReferencedSOPInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.ReferencedSOPInstanceUID]

    if (ds.StructureSetROISequence !== undefined) {
        for (let i = 0; i < ds.StructureSetROISequence.length; i++) {
            if (ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID !== undefined) {
                ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID = dicomMapAnonReal.anon2realUidMap[ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID]
            }
        }
    }
    if (ds.ROIContourSequence !== undefined) {
        for (let i = 0; i < ds.ROIContourSequence.length; i++) {
            if (ds.ROIContourSequence[i].ContourSequence !== undefined) {
                for (let j = 0; j < ds.ROIContourSequence[i].ContourSequence.length; j++) {
                    if (ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence !== undefined
                        && ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID) {
                        ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID =
                            dicomMapAnonReal.anon2realUidMap[ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID]
                    }
                }
            }
        }
    }
    if (ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence !== undefined) {
        for (let i = 0; i < ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence.length; i++) {
            if (ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID !== undefined) {
                ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID =
                    dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID]
            }
        }
    }

    dicomDict.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(ds);
    return dicomDict.write();
}
