import { rtViewerApiClient } from "../web-apis/rtviewer-api-client";
import _ from "lodash";
import { getUserCanEditStructureSet, RTStructLockDictionary } from "./locks";
import { User } from "../store/user";
import { SimilarityMetrics, Metric } from "../web-apis/similarity-metrics";

export type TrainingTask = {
    id: string,
    name: string,
    description: string,
    comments: string,
    storageAccount: string,
    fileShare: string,
    state: TrainingTaskState,
    grade: string,
    tExpiryUnix: number | Date,
    tStartedUnix: number | Date,
    tFinishedUnix: number | Date,
    traineeStructureSet:
    {
        seriesInstanceUid: string,
        sopInstanceUid: string,
        label: string,
        rois: {
            roiName: string,
            roiIdx: number,
            comments: string,
            grade: string,
            similarityMetrics?: SimilarityMetrics,
        }[],
    },
    azurePath: string,
    patientId: string,
    forUid: string,
    seriesInstanceUid: string,
    modality: string,
    gtStructureSet:
    {
        seriesInstanceUid: string,
        sopInstanceUid: string,
        label: string,
        rois: {
            roiName: string,
            roiIdx: number,
            comments: string,
            acceptanceCriteria: any
        }[],
    },
    trainee: {
        user_id: string,
        user_name: string,
    },
    supervisor: {
        user_id: string,
        user_name: string,
    },
    type: string,
    tCreatedUnix: number,
    archived: boolean,
    version: string,
}

export type TrainingTasks = TrainingTask[];


export enum SortType {
    NameAsc = 'name-asc',
    NameDesc = 'name-desc',
    CreationAsc = 'creation-asc',
    CreationDesc = 'creation-desc',
    StateAsc = 'state-asc',
    StateDesc = 'state-desc',
    GradeAsc = 'grade-asc',
    GradeDesc = 'grade-desc',
    FinishedAsc = 'finished-asc',
    FinishedDesc = 'finished-desc'
}


export enum Filter {
    NOT_STARTED = 'NOT_STARTED',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    GRADED = 'GRADED',
}

export function isTrainingTask(obj: any): obj is TrainingTask {
    // these quick checks are good enough
    if (
        obj !== undefined &&
        _.has(obj, 'id') &&
        _.has(obj, 'traineeStructureSet') &&
        _.has(obj, 'traineeStructureSet.sopInstanceUid') &&
        _.has(obj, 'forUid') &&
        _.has(obj, 'seriesInstanceUid') &&
        _.has(obj, 'gtStructureSet') &&
        _.has(obj, 'gtStructureSet.sopInstanceUid') &&
        _.has(obj, 'trainee') &&
        _.has(obj, 'supervisor')
    ) return true;

    return false;
}

export type TrainingTaskRoi = {
    roiName: string,
    roiIdx: number,
    comments?: string,
    grade?: string,
    similarityMetrics?: CalculatedMetrics
    acceptanceCriteria?: CalculatedMetrics
}

export type CalculatedMetrics = {
    dice?: number;
    sdice1?: number;
    sdice2?: number;
    sdice3?: number;
    sdice5?: number;
    hd?: number;
    hd95?: number;
    mean_hd?: number;
    average_apl_mm?: number;
    total_apl_mm?: number;
    user_vol_mm3?: number,
    gt_vol_mm3?: number,
}

export const getCalculatedMetric = (metric: Metric): string => {
    switch (metric) {
        case Metric.AverageAplMm:
            return 'average_apl_mm';
        case Metric.Dice:
            return 'dice';
        case Metric.HD:
            return 'hd';
        case Metric.HD95:
            return 'hd95';
        case Metric.MeanHD:
            return 'mean_hd';
        case Metric.SDice1:
            return 'sdice1';
        case Metric.SDice2:
            return 'sdice2';
        case Metric.SDice3:
            return 'sdice3';
        case Metric.SDice5:
            return 'sdice5';
        case Metric.TotalAplMm:
            return 'total_apl_mm';
        default:
            throw new Error(`Unsupported metric: ${metric}`);
    }
}

/**
 * Construct a visibility collection from provided task, or return defaults.
 */
export const getDefaultVisibleMetrics = (task: TrainingTask | undefined): { [m in Metric]: boolean } => {
    let defaultVisibility: { [metric: string]: boolean } | undefined = undefined;
    if (task && task.gtStructureSet.rois) {
        defaultVisibility = {};
        for (const roi of task.gtStructureSet.rois) {
            for (const [metric, value] of Object.entries(roi.acceptanceCriteria)) {
                if (_.isNumber(value)) {
                    defaultVisibility[metric] = true;
                }
            }
        }
    }

    return defaultVisibility && Object.keys(defaultVisibility).length > 0 ?
        {
            [Metric.Dice]: defaultVisibility[getCalculatedMetric(Metric.Dice)] || false,
            [Metric.SDice1]: defaultVisibility[getCalculatedMetric(Metric.SDice1)] || false,
            [Metric.SDice2]: defaultVisibility[getCalculatedMetric(Metric.SDice2)] || false,
            [Metric.SDice3]: defaultVisibility[getCalculatedMetric(Metric.SDice3)] || false,
            [Metric.SDice5]: defaultVisibility[getCalculatedMetric(Metric.SDice5)] || false,
            [Metric.HD]: defaultVisibility[getCalculatedMetric(Metric.HD)] || false,
            [Metric.HD95]: defaultVisibility[getCalculatedMetric(Metric.HD95)] || false,
            [Metric.MeanHD]: defaultVisibility[getCalculatedMetric(Metric.MeanHD)] || false,
            [Metric.AverageAplMm]: defaultVisibility[getCalculatedMetric(Metric.AverageAplMm)] || false,
            [Metric.TotalAplMm]: defaultVisibility[getCalculatedMetric(Metric.TotalAplMm)] || false,
        } : {
            [Metric.Dice]: true,
            [Metric.SDice2]: true,
            [Metric.HD95]: true,
            [Metric.SDice1]: false,
            [Metric.SDice3]: false,
            [Metric.SDice5]: false,
            [Metric.AverageAplMm]: false,
            [Metric.TotalAplMm]: false,
            [Metric.HD]: false,
            [Metric.MeanHD]: false,
        };
}

export type TrainingTaskGroup = {
    name: string;
    collection: string;
    latestCreationTime: number;
    trainingTasks: TrainingTask[];
}

export enum TrainingTaskState {
    NotStarted = 'NOTSTARTED',
    Started = 'STARTED',
    Finished = 'FINISHED',
    Graded = 'GRADED',
    Archived = 'ARCHIVED'
}

export const getTaskDownloadKey = (task: TrainingTask): string => `task_${task.id}`;

export const getTrainingTaskGroups = (tasks: TrainingTask[]): TrainingTaskGroup[] => {
    const groupedByName = _.groupBy(tasks, t => `${t.name}+++____+++${t.fileShare}`);
    const taskGroups: TrainingTaskGroup[] = Object.keys(groupedByName).map(k => ({
        name: groupedByName[k][0].name,
        collection: groupedByName[k][0].fileShare,
        latestCreationTime: Math.max(...(groupedByName[k].map(t => t.tCreatedUnix))),
        trainingTasks: groupedByName[k]
    }));
    // const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    // return taskGroups.sort((a, b) => collator.compare(a.name, b.name));
    return taskGroups.sort((a, b) => b.latestCreationTime - a.latestCreationTime);
};

export const getSearchedTrainingTasks = (trainingTasks: TrainingTask[], searchText: string) => {
    if (!searchText) {
        return getTrainingTaskGroups(trainingTasks);
    }

    const searchWords = searchText.toLowerCase().split(/\s+/);
    const filteredTasks = _.filter(trainingTasks, task => {
        const taskFields = [
            'name', 'description', 'comments',
            'trainee.user_name', 'supervisor.user_name',
            'state', 'type'
        ];
        return _.some(taskFields, field =>
            _.some(searchWords, word =>
                _.get(task, field, '').toLowerCase().includes(word)
            )
        );
    });

    return getTrainingTaskGroups(filteredTasks);
};

export const getMultipleFilteredTrainingTaskGroups = (
    taskGroups: TrainingTaskGroup[],
    filters: Filter[]
): TrainingTaskGroup[] => {
    if (!filters || filters.length === 0) {
        // Return all task groups and their corresponding training tasks.
        return taskGroups.map((group) => ({
            ...group,
            trainingTasks: [...group.trainingTasks],
        }));
    }

    const validTaskStates: TrainingTaskState[] = [];

    for (const filter of filters) {
        switch (filter) {
            case Filter.NOT_STARTED:
                validTaskStates.push(TrainingTaskState.NotStarted);
                break;
            case Filter.STARTED:
                validTaskStates.push(TrainingTaskState.Started);
                break;
            case Filter.FINISHED:
                validTaskStates.push(TrainingTaskState.Finished);
                break;
            case Filter.GRADED:
                validTaskStates.push(TrainingTaskState.Graded);
                break;
            default:
                break;
        }
    }

    const filteredTaskGroups: TrainingTaskGroup[] = [];

    for (const group of taskGroups) {
        const filteredTasks = group.trainingTasks.filter((task) =>
            validTaskStates.includes(task.state)
        );

        if (filteredTasks.length > 0) {
            filteredTaskGroups.push({
                ...group,
                trainingTasks: filteredTasks,
            });
        }
    }

    return filteredTaskGroups;
};


export const handleTaskState = (stateText: string) => {
    const state = stateText.trim().toUpperCase();

    switch (state) {
        case TrainingTaskState.NotStarted:
            return 'Not started';
        case TrainingTaskState.Started:
            return 'Started';
        case TrainingTaskState.Finished:
            return 'Finished';
        case TrainingTaskState.Graded:
            return 'Graded';
        case TrainingTaskState.Archived:
            return 'Archived';
        default:
            return 'Unknown';
    }
}

export const handleTaskType = (typeText: string) => {
    const type = typeText.trim().toUpperCase();
    if (type === 'PRACTICE') {
        return 'Practice';
    } else if (type === 'TEST') {
        return 'Test';
    } else {
        return 'Unknown';
    }
}



/**
 * Sorts an array of task groups based on a specified sort type and direction.
 * @param taskGroups Array of task groups to sort.
 * @param sortType The type of value to sort.
 * @returns The sorted array of task groups.
 */
export const getSortedTrainingTaskGroups = (taskGroups: TrainingTaskGroup[], sortType: SortType): TrainingTaskGroup[] => {
    const direction = sortType.split('-')[1];
    const value = sortType.split('-')[0];
    const getValue = (taskGroup: TrainingTaskGroup): any => {
        switch (value) {
            case 'name':
                return taskGroup.name;
            case 'grade':
                return taskGroup.trainingTasks[0].grade;
            default:
                return 0;
        }
    };

    // Sort the task groups based on the specified sort type and direction.
    return taskGroups.sort((a, b) => {
        const aValue = getValue(a);
        const bValue = getValue(b);
        const result = typeof aValue === 'string' && typeof bValue === 'string' ? aValue.localeCompare(bValue, undefined, { sensitivity: 'accent' }) : 0;
        return direction === 'desc' ? -result : result;
    });
};



/**
 * create task when form is submitted
 * @param task the task data to create
 */

export async function createTask(task: TrainingTask) {
    await rtViewerApiClient.createTask(task);
}

export async function createBatchTasks(tasks: any) {
    await rtViewerApiClient.batchCreateTasks(tasks);
}

/** 
 * archive task when form is submitted
 * @param task the task data to archive
 * 
 */

export async function archiveTask(task: TrainingTask) {
    await rtViewerApiClient.archiveTask(task);
}

export async function unarchiveTask(task: TrainingTask) {
    await rtViewerApiClient.unarchiveTask(task);
}

/**
 * delete task when delete button is clicked
 * @param task the task data to delete
 */
export async function deleteTask(task: TrainingTask) {
    await rtViewerApiClient.deleteTask(task);
}

/**
 * load the tasks from the JSON  file in the share.
 * @param clientId The client id coming from app auth
 */

export async function downloadTasks(): Promise<TrainingTask[]> {
    const tasks = await rtViewerApiClient.getAvailableTasks();
    return tasks;
}

export async function downloadArchivedTasks(): Promise<TrainingTask[]> {
    const tasks = await rtViewerApiClient.getAvailableTasks(true);
    let archived = tasks.filter(task => task.archived === true);
    return archived;
}

export async function downloadGTRois(storageAccountName: string, fileShareName: string): Promise<string[]> {
    const rois = await rtViewerApiClient.getAvailableGtRois(storageAccountName, fileShareName);
    return rois;
}

/** Returns true if user has a valid lock to the task AND is either a trainee of the task OR a supervisor or admin (in general). */
export function getUserCanEditTask(user: User, task: TrainingTask, lockDictionary: RTStructLockDictionary): boolean {
    if (user.isAuthenticated && user.permissions.isTrainee && user.isTaskTrainee(task) &&
        (task.state === TrainingTaskState.NotStarted || task.state === TrainingTaskState.Started)) {
        // user is a trainee and the task is either 'not started' or 'started'
        return getUserCanEditStructureSet(user, task.traineeStructureSet.sopInstanceUid, lockDictionary);
    } else if (user.isAuthenticated && user.permissions.isSupervisor &&
        (task.state === TrainingTaskState.NotStarted || task.state === TrainingTaskState.Started || task.state === TrainingTaskState.Finished)) {
        // user is a supervisor
        return getUserCanEditStructureSet(user, task.traineeStructureSet.sopInstanceUid, lockDictionary);
    }
    else return false;
}

export function getTrainingTaskListId(task: TrainingTask): string {
    return `mv-ttli-${task.id}`;
}
