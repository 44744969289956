import React from 'react';
import { RouteComponentProps } from 'react-router';

import ReferencePage from './ReferencePage';


type AllProps = RouteComponentProps;


class SupportingScanPage extends React.Component<AllProps> {
    displayName = SupportingScanPage.name;

    render = () => {
        return (
            <ReferencePage
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
                isSupportingScan
            />
        );
    }
}

export default SupportingScanPage;
