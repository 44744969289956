import { StructureSet } from "../dicom/structure-set";
import { ImageSlice, Image, Modality } from "../dicom/image";
import { AzureFileInfo } from "../web-apis/azure-files";
import { TrainingTask, isTrainingTask } from "../datasets/training-task";
import { DatasetImage } from "../datasets/dataset-image";
import _ from "lodash";
import { UrlQuery } from "./url-query";

export type Scan = {
    scanId: string;
    modality: Modality;
    seriesDescription?: string;
    protocolName?: string;
    studyDescription?: string;
    bodyPartExamined?: string;
    structureSets: { [ssId: string]: StructureSet };

    image?: Image;

    slices: { [sliceId: string]: Slice };
};

export type Slice = {
    sliceId: string;
    imageSlice: ImageSlice;
    arrayBuffer: ArrayBuffer;
    filename: string;
};

export function createScan(
    scanId: string,
    modality?: Modality,
    seriesDescription?: string,
    protocolName?: string,
    studyDescription?: string,
    bodyPartExamined?: string): Scan {
    return {
        scanId,
        modality: modality || Modality.Unknown,
        seriesDescription,
        protocolName,
        studyDescription,
        bodyPartExamined,
        structureSets: {},
        slices: {},
    };
}

/** Updates a scan and re-initializes it if it was never properly initialized with a slice or an image */
export function updateScan(scan: Scan, slice: Slice): void;
export function updateScan(scan: Scan, image: Image): void;
export function updateScan(scan: Scan, sliceOrImage: Slice | Image) {
    if (sliceOrImage instanceof Image) {
        const image = sliceOrImage;
        if (Object.keys(scan.slices).length === 0) {
            scan.modality = image.modality;
            scan.seriesDescription = image.seriesDescription;
        }

        scan.image = image;
    } else {
        const slice = sliceOrImage;
        if (Object.keys(scan.slices).length === 0) {
            scan.modality = slice.imageSlice.modality;
            scan.seriesDescription = slice.imageSlice.seriesDescription;
        }

        scan.slices[slice.sliceId] = slice;
    }
}

export function getScanId(imageSlice: ImageSlice, fileInfo?: AzureFileInfo): string;
export function getScanId(structureSet: StructureSet, fileInfo?: AzureFileInfo): string;
export function getScanId(trainingTask: TrainingTask): string;
export function getScanId(datasetImage: DatasetImage): string;
export function getScanId(seriesInstanceUid: string, patientId: string, fileInfo?: AzureFileInfo): string;
export function getScanId(urlQuery: UrlQuery): string;
export function getScanId(argA: ImageSlice | StructureSet | TrainingTask | DatasetImage | string | UrlQuery, argB?: AzureFileInfo | string, argC?: AzureFileInfo): string {

    let seriesInstanceUid: string;
    let patientId: string;
    let datasetIdentifier = '';
    let storageAccountName = '';
    let fileShareName = '';

    if (argA instanceof ImageSlice) {
        seriesInstanceUid = argA.seriesInstanceUID;
        patientId = argA.patientId;
    } else if (argA instanceof StructureSet) {
        seriesInstanceUid = argA.dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID;
        patientId = argA.patientId;
    } else if (argA instanceof DatasetImage) {
        return argA.scanId;
    } else if (isTrainingTask(argA)) {
        storageAccountName = argA.storageAccount;
        fileShareName = argA.fileShare;
        seriesInstanceUid = argA.seriesInstanceUid;
        patientId = argA.patientId;
    } else if (_.isString(argA) && _.isString(argB)) {
        seriesInstanceUid = argA;
        patientId = argB;

        if (argC && argC instanceof AzureFileInfo) {
            storageAccountName = argC.storageAccountName;
            fileShareName = argC.fileShareName;
        }

    } else if (argA instanceof UrlQuery) {
        if ([argA.imageSeriesInstanceUid, argA.storageAccount, argA.fileShare, argA.patientId].some(a => !a)) {
            throw new Error('Cannot create a scanId from query parameters');
        }

        seriesInstanceUid = argA.imageSeriesInstanceUid!;
        storageAccountName = argA.storageAccount!;
        fileShareName = argA.fileShare!;
        patientId = argA.patientId!;
    }
    else {
        throw new Error('Unsupported input object');
    }

    if (argB instanceof AzureFileInfo) {
        storageAccountName = argB.storageAccountName;
        fileShareName = argB.fileShareName;
    }

    if (!patientId) {
        console.error('Slice has no Patient ID -- this scan can be opened but things may behave in an unexpected manner');
    }
    if (!seriesInstanceUid) {
        throw new Error('Slice has invalid Series Instance UID');
    }

    if (storageAccountName && fileShareName) {
        datasetIdentifier = `-${storageAccountName}-${fileShareName}--`;
    }

    return `scan-${datasetIdentifier}${patientId}-${seriesInstanceUid}`;
}

export function getSliceId(imageSlice: ImageSlice): string {
    if (!imageSlice.sopInstanceUID) {
        throw new Error('Slice has invalid SOP Instance UID');
    }

    return `slice-${imageSlice.sopInstanceUID}`;
}
