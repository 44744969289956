/* File browser component, shows directory tree of selected Azure fileshare. Any dicom files can be loaded.
Supported modalities are CT, MR and RTSTRUCT */

import React from 'react';
import { Button, ButtonGroup, DropdownButton, Dropdown, Col, Row, Card } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { produce } from 'immer';

import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';
import { rtViewerApiClient } from '../../web-apis/rtviewer-api-client';
import { GradingWorkflowState } from '../../datasets/roi-grading';
import { getUserCanEdit } from '../../datasets/dataset-files';
import { DownloadTask } from '../../store/file-transfer-task';
import { AzureShareInfo } from '../../web-apis/azure-files';
import { AzureFileClient } from '../../web-apis/azure-file-client';
import DatasetFilter, { FilterOperator } from './models/DatasetFilter';
import FilterDialog from './FilterDialog';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import DatasetPage from './dataset-table/DatasetPage';

import 'react-contexify/dist/ReactContexify.min.css';
import './DatasetBrowser.css';
import WorkState, { Workspace } from '../../store/work-state';
import TasksPage from './tasks-table/TasksPage';
import TasksDialog from '../common/dialogs/TasksDialog';
import AddTaskDialog from '../common/dialogs/AddTaskDialog';
import { RouteComponentProps } from 'react-router';
import { TrainingTask } from '../../datasets/training-task';
import ReferenceLibraryPage from '../reference-library-page/ReferenceLibraryPage';
import { LockAction } from '../../datasets/locks';
import TaskDescriptionDialog from '../common/dialogs/TaskDescriptionDialog';
import { selectTasksGroupedByName } from '../../store/selectors';


type FileShareCollection = { [storageAccountName: string]: AzureShareInfo[] | null };

export enum DatasetBrowserType { AnnotationBrowser, TrainingTaskBrowser, ReferenceLibraryBrowser };

// combine redux & selector props
const mapStateToProps = (state: StoreState) => Object.assign({}, state,
    {
        taskGroups: selectTasksGroupedByName(state),
    });
const connector = connect(mapStateToProps, sagas.mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
    isVisible: boolean,
    viewImage?: (datasetImage: DatasetImage, dataset: Dataset, canEdit: boolean) => void,
    /** Optional work state that the dataset browser should automatically initialize itself to. */
    initialWorkState?: WorkState,
    type: DatasetBrowserType,
}

type DispatchProps = {
    startBatchJobRequest(): void,
    finishBatchJobRequest(wasSuccessful: boolean): void,
    lockDatasetImage(datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction): void,
    downloadDataset(azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string): void,
    downloadDatasetImage(dataset: Dataset, datasetImage: DatasetImage): void,
    unloadImageAndStructureSets(datasetImage: DatasetImage): void,
    setCurrentWorkState(workState: WorkState | null): void,
    setCurrentTask(task: TrainingTask): void,
    getPatients(patients: string[]): void,
    openTask(task: TrainingTask | undefined): void,
    setTaskDescriptionDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps & RouteComponentProps & ReduxProps;

type OwnState = {

    /** The storage accounts available for the logged-in user. */
    storageAccountNames?: string[],

    /** Available file shares sorted by storage account. */
    fileShares: FileShareCollection,

    /** Optional error object we can display to user. */
    error?: any,

    /** Name of currently selected storage account. */
    currentStorageAccount?: string,

    /** Current file share as an azure share object. */
    currentFileShare?: AzureShareInfo,

    /** ID of currently selected dataset (basically corresponds to a file share). */
    currentDatasetId?: string,

    /** Second header row on the page displaying patient count statistics on current dataset.
     * Convenience object so we can update the header easily from DatasetPage after applying a filter. */
    patientCountHeader: string,

    /** Set to true if dataset browser is currently in the process of initializing to a set start state. */
    isInitializing: boolean,

    /** Set to true if dataset browser has been initialized to a set start state. */
    hasInitialized: boolean,

    /** The work state that the dataset browser will initiate itself to. Note that this is duplicated from the props
     * and stored inside this component for convenience.
     */
    initialWorkState: WorkState | null,

    // filter stuff
    showFilterModal: boolean,
    showTasks: boolean,
    filterText?: string,
    filterOperator: FilterOperator,
    filterCaseSensitive: boolean,
    filterUseOriginal: boolean,
    filterGradingStates: GradingWorkflowState[],
    filterGradingStateOptions: any[],
    filterChanged: boolean,
    notificantionMessage?: string,
    isAddTaskDialogVisible: boolean,
    isDuplicateTaskDialogVisible: boolean,
}

class DatasetBrowser extends React.Component<AllProps, OwnState> {
    displayName = DatasetBrowser.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            fileShares: {},
            patientCountHeader: '',
            isInitializing: false,
            hasInitialized: false,
            initialWorkState: null,
            showFilterModal: false,
            showTasks: false,
            filterOperator: FilterOperator.Or,
            filterCaseSensitive: false,
            filterUseOriginal: true,
            filterGradingStates: [],
            filterGradingStateOptions: [],
            filterChanged: false,
            notificantionMessage: undefined,
            isAddTaskDialogVisible: false,
            isDuplicateTaskDialogVisible: false,
        };

        rtViewerApiClient.getAnnotationStorages().then(async (saNames) => {
            this.setState({ storageAccountNames: saNames });
        });
    }

    componentDidUpdate = (prevProps: AllProps, prevState: OwnState) => {

        // initialize component in the background if we're given a valid work state to initialize to,
        // but only ever do this once
        if (!this.state.hasInitialized && !this.state.isInitializing && this.state.initialWorkState === null) {
            if (this.props.initialWorkState !== undefined) {
                // initialize dataset browser from url query

                const { initialWorkState } = this.props;

                // set up the correct initialization depending whether we're initializing into an auto-loaded scan
                // or just a dataset page index
                if (initialWorkState.hasAnnotationWork() || initialWorkState.hasDatasetIndex()) {
                    const datasetFile = initialWorkState.hasAnnotationWork() ? initialWorkState.dataset!.datasetFile : null;
                    const initialStorageAccount = initialWorkState.hasAnnotationWork() ? datasetFile!.storageAccountName : initialWorkState.storageAccount!;
                    const shareInfo = initialWorkState.hasAnnotationWork() ? datasetFile!.getShare() :
                        initialWorkState.fileShare !== null ? new AzureShareInfo(initialStorageAccount, initialWorkState.fileShare) : undefined;
                    const datasetId = initialWorkState.hasAnnotationWork() || shareInfo !== undefined ? Dataset.generateDatasetId(shareInfo!) : undefined;
                    this.setState({
                        // store the requested initial work state into this component's own state
                        initialWorkState: initialWorkState,
                        isInitializing: true,
                        currentStorageAccount: initialStorageAccount,
                        currentFileShare: shareInfo,
                        currentDatasetId: datasetId
                    });

                    // load fileshares here, since we have an initial work state here we're assuming that
                    // the actual dataset itself has already been loaded by now by some other component
                    this.handleSelectStorage(initialStorageAccount);
                }
            } else {
                // initialize dataset browser with default reference library storage account (if found), or
                // if there's only one storage account then that, otherwise select nothing

                // set internal state, then perform initialization in the callback function afterwards
                this.setState({
                    isInitializing: true,
                }, async () => {
                    let autoSelectedStorage = '';

                    const { storageAccountNames } = this.state;
                    if (!storageAccountNames) { return; }

                    // auto-select storage account from config.json if it's available
                    const defaultStorage = this.props.deploymentConfigInfo ? this.props.deploymentConfigInfo.defaultReferenceLibraryStorageAccount : undefined;
                    if (defaultStorage && storageAccountNames.includes(defaultStorage)) {
                        autoSelectedStorage = defaultStorage;
                    } else if (storageAccountNames.length === 1) {
                        // otherwise auto-select storage account if there's just one available
                        autoSelectedStorage = storageAccountNames[0];
                    }

                    if (autoSelectedStorage) {
                        this.handleSelectStorage(autoSelectedStorage);
                        const azureClient = new AzureFileClient(autoSelectedStorage);
                        const fileShares = await azureClient.listFileShares();
                        const firstFileShare = fileShares.length > 0 ? fileShares[0] : undefined;
                        if (firstFileShare) {
                            this.handleSelectFileShare(fileShares[0], false);
                        }

                        this.setState({ currentStorageAccount: autoSelectedStorage, currentFileShare: firstFileShare });
                    }
                });


            }
        }

        if (this.props.isVisible && !prevProps.isVisible) {
            // Refresh the page if we show it again (e.g. when coming back from rtviewer).
            // Don't clear the header if we have one! This will prevent the header image counts from
            // flickering on and off.
            this.handleRefreshClick(false);
        }


    }

    isFilterOn = () => {
        return this.state.filterText || this.state.filterGradingStates.length > 0;
    }

    getCurrentFilter = (): DatasetFilter | undefined => {
        return this.isFilterOn() ? new DatasetFilter(this.state.filterText || '', this.state.filterOperator,
            this.state.filterCaseSensitive, this.state.filterUseOriginal, this.state.filterGradingStates)
            : undefined;
    }

    setPageError = (error: any) => {
        this.setState(produce((draft: OwnState) => {
            draft.error = error;
        }));
    }

    setPatientCountHeader = (header: string) => {
        this.setState(produce((draft: OwnState) => {
            draft.patientCountHeader = header;
        }));
    }

    getCurrentDataset = (): Dataset | undefined => {
        return this.state.currentDatasetId !== undefined ? this.props.datasets[this.state.currentDatasetId] : undefined;
    }

    getWorkspaceForWorkState = (): Workspace => {
        switch (this.props.type) {
            case DatasetBrowserType.AnnotationBrowser:
                return Workspace.Annotation;
            case DatasetBrowserType.ReferenceLibraryBrowser:
                return Workspace.ReferenceLibrary;
            case DatasetBrowserType.TrainingTaskBrowser:
                return Workspace.GuidelineTraining;
            default:
                throw new Error('Unsupported dataset browser type');
        }
    }

    /** Download dataset information for a task to prepare the task duplication dialog  */
    loadDatasetForDuplication = (azureShare: AzureShareInfo) => {
        const datasetId = Dataset.generateDatasetId(azureShare);
        this.props.downloadDataset(azureShare, true, datasetId);
        this.setState({ currentFileShare: azureShare });
    }

    handleShowFilterModal = (show: boolean) => {
        this.setState({ showFilterModal: show });
        if (!show && this.state.filterChanged) {
            this.handleRefreshClick();
            this.setState({ filterChanged: false });
        }
    }

    handleFilterTextChange = (newFilter: string) => {
        this.setState({ filterText: newFilter, filterChanged: true })
    }

    handleCreateFilterClick = () => {
        this.setState({ filterText: "", showFilterModal: true });
    }

    handleEditFilterClick = () => {
        this.setState({ showFilterModal: true });
    }

    handleDeleteFilterClick = () => {
        this.setState({ filterText: undefined, showFilterModal: false, filterGradingStateOptions: [], filterGradingStates: [], filterChanged: false }, () => this.handleRefreshClick());
    }

    handleFilterCaseSensitiveChange = (isCaseSensitivityOn: boolean) => {
        this.setState({ filterCaseSensitive: isCaseSensitivityOn, filterChanged: true });
    }

    handleFilterUseOriginalChange = (isUseOriginalOn: boolean) => {
        this.setState({ filterUseOriginal: isUseOriginalOn, filterChanged: true });
    }

    handleFilterGradingStatesChange = (filteredStateOptions: any) => {
        const incomingFilteredStateOptions = filteredStateOptions || [];
        const filterGradingStates = incomingFilteredStateOptions.map((f: any) => f.value);

        // set these filtered values twice into state - one for the actual filtering (this array includes values only),
        // and the other for our dropdown controlled component so it knows which values are currently selected
        // (this array includes both values and labels)
        this.setState({ filterGradingStates, filterGradingStateOptions: incomingFilteredStateOptions, filterChanged: true });
    }

    handleOpenAddTaskDialog = () => {
        this.setState({ isAddTaskDialogVisible: true });
    }

    handleCloseAddTaskDialog = () => {
        this.setState({ isAddTaskDialogVisible: false });
    }


    handleOpenTaskDescriptionDialog = () => {
        this.props.setTaskDescriptionDialogVisibility(true);
    }

    handleCloseTaskDescriptionDialog = () => {
        this.props.setTaskDescriptionDialogVisibility(false);
    }


    handleSelectStorage = (storageAccountName: string) => {
        this.setState(produce((draft: OwnState) => {
            draft.currentStorageAccount = storageAccountName;
            draft.error = null;
            draft.fileShares = {};

            // reset any existing fileshare selection -- unless we're initializing
            if (!draft.isInitializing) {
                draft.currentFileShare = undefined;
                draft.currentDatasetId = undefined;
                draft.patientCountHeader = '';

                // set current work state to matching dataset index (unless we're initializing because that would mess up the URL)
                this.props.setCurrentWorkState(new WorkState(storageAccountName, null, null, this.getWorkspaceForWorkState()));
            }

        }), async () => {

            const azureClient = new AzureFileClient(storageAccountName);
            try {
                const fileShares = await azureClient.listFileShares();
                this.setState(produce((draft: OwnState) => {
                    draft.fileShares[storageAccountName] = fileShares;

                    // take the isInitializing flag off at this point -- its use as a safeguard has run out
                    draft.isInitializing = false;
                    // instead, we're now done with initialization
                    draft.hasInitialized = true;
                }));
            }
            catch (err) {
                console.log(err);
                alert("Error. Check console for details. (F12)");
            }
        });
    }

    handleSetNotificationMessage = (message: string) => {
        this.setState({ notificantionMessage: message });
    }

    handleSelectFileShare = (shareInfo: AzureShareInfo, clearHeader: boolean) => {
        const datasetId = Dataset.generateDatasetId(shareInfo);

        this.setState(produce((draft: OwnState) => {
            draft.currentFileShare = shareInfo;
            draft.currentDatasetId = datasetId;
            draft.error = null;
            if (clearHeader) {
                draft.patientCountHeader = '';
            }
        }));

        // set current work state to matching dataset index (unless we're initializing because that would mess up the URL)
        if (!this.state.isInitializing) {
            this.props.setCurrentWorkState(new WorkState(shareInfo.storageAccountName, shareInfo.fileShareName, null, this.getWorkspaceForWorkState()));
        }

        // load dataset in the background
        this.props.downloadDataset(shareInfo, true, datasetId);
    }

    handleRefreshClick = (clearHeader: boolean = true) => {
        const { currentFileShare } = this.state;
        if (currentFileShare) {
            const datasetId = Dataset.generateDatasetId(currentFileShare!);
            this.props.downloadDataset(currentFileShare, true, datasetId);
            this.handleSelectFileShare(currentFileShare, clearHeader);
        }
    }

    handleLockClick = async (datasetImage: DatasetImage, lockAction: LockAction) => {
        const download: DownloadTask = this.props.downloads[datasetImage.downloadKey];
        if (lockAction === LockAction.Unlock && download && !download.ready && !download.failed) { return; };
        if (lockAction === LockAction.Unlock) { this.handleUnloadClick(datasetImage); }
        const currentDataset = this.getCurrentDataset();
        if (currentDataset !== undefined) {
            this.props.lockDatasetImage(datasetImage, currentDataset, lockAction);
        }
    }

    handleLoadClick = async (datasetImage: DatasetImage) => {
        const dataset = this.getCurrentDataset();
        if (dataset !== undefined) {
            this.props.downloadDatasetImage(dataset, datasetImage);
        }
    }

    handleUnloadClick = (datasetImage: DatasetImage) => {
        this.props.unloadImageAndStructureSets(datasetImage);
    }

    handleViewImageClick = (datasetImage: DatasetImage) => {
        const dataset = this.getCurrentDataset();

        if (!this.props.viewImage) {
            throw new Error('No view image function set');
        }

        if (dataset !== undefined) {
            const canEdit = getUserCanEdit(this.props.user!, this.props.datasetLocks[datasetImage.datasetId], datasetImage.seriesId);
            this.props.viewImage(datasetImage, dataset, canEdit);
        }
    }


    handleViewTaskClick = (task: TrainingTask) => {
        this.props.openTask(task);
    }

    handleOpenDuplicateTaskDialog = () => {
        this.setState({ isDuplicateTaskDialogVisible: true });
    }

    handleCloseDuplicateTaskDialog = () => {
        this.setState({ isDuplicateTaskDialogVisible: false });

        // set current task to undefined (mainly just in case)
        // some of the other dialogs perform this within the components themselves on close
        // TODO: probably shouldn't do that but rather do it all in DatasetBrowser or in whatever
        // is the relevant parent component for those dialogs
        this.props.setCurrentTask(undefined);
    }


    render = () => {
        const storageAccountName = this.state.currentStorageAccount;
        const storageAccountDropdownTitle = storageAccountName || "Storage account";
        const fileShares = storageAccountName ? this.state.fileShares[storageAccountName] : null;
        const currentFileShare = this.state.currentFileShare;
        const currentDataset = this.getCurrentDataset();
        const isDatasetLoaded = currentDataset !== undefined;

        const tasks = this.props.trainingTasks!;
        const roiNamesOriginal = isDatasetLoaded ? Array.from(currentDataset!.originalRois).sort().join(", ") : null;
        const roiNamesStandard = isDatasetLoaded ? Array.from(currentDataset!.standardRois).sort().join(", ") : null;

        const mainHeader = this.props.type !== DatasetBrowserType.TrainingTaskBrowser && currentFileShare ? currentFileShare.fileShareName : '';
        const isFilterOn = this.isFilterOn();
        const showChars = 15;
        let ft = this.state.filterText || (isFilterOn ? "<filter>" : "");
        ft = (ft.length > showChars) ? ft.substring(0, showChars) + "..." : ft;
        const operatorTitles = {
            [FilterOperator.And]: "AND",
            [FilterOperator.Or]: "OR"
        }

        let headerText = '';
        if (this.props.type === DatasetBrowserType.TrainingTaskBrowser) {
            headerText = "";
        } else if (this.props.type === DatasetBrowserType.ReferenceLibraryBrowser) {
            headerText = "Collection: ";
        } else if (this.props.type === DatasetBrowserType.AnnotationBrowser) {
            headerText = "Dataset: ";
        }

        const showDatasetSelector = this.props.type !== DatasetBrowserType.TrainingTaskBrowser;

        let currentPage;
        if (this.props.type === DatasetBrowserType.TrainingTaskBrowser) {
            if (tasks) {
                currentPage = <TasksPage
                    error={this.state.error}
                    setPageError={this.setPageError}
                    initialWorkState={this.state.initialWorkState}
                    handleViewTaskClick={this.handleViewTaskClick}
                />
            } else {
                currentPage = <div className="dataset-browser-loading">Loading tasks...</div>
            }
        } else if (this.props.type === DatasetBrowserType.ReferenceLibraryBrowser) {
            if (tasks) {
                currentPage = <ReferenceLibraryPage
                    storageAccountName={this.state.currentStorageAccount}
                    fileShare={this.state.currentFileShare}
                    dataset={currentDataset}
                    error={this.state.error}
                    setPageError={this.setPageError}
                    setPatientCountHeader={this.setPatientCountHeader}
                    getCurrentFilter={this.getCurrentFilter}
                    filterText={this.state.filterText}
                    initialWorkState={this.state.initialWorkState}
                    onLockClick={this.handleLockClick}
                    onLoadClick={this.handleLoadClick}
                    onUnloadClick={this.handleUnloadClick}
                    onViewImageClick={this.handleViewImageClick}
                    onOpenAddTaskDialogClick={this.handleOpenAddTaskDialog}
                />
            }
        } else if (this.props.type === DatasetBrowserType.AnnotationBrowser) {
            currentPage = <DatasetPage
                storageAccountName={this.state.currentStorageAccount}
                fileShare={this.state.currentFileShare}
                dataset={currentDataset}
                error={this.state.error}
                setPageError={this.setPageError}
                setPatientCountHeader={this.setPatientCountHeader}
                getCurrentFilter={this.getCurrentFilter}
                filterText={this.state.filterText}
                initialWorkState={this.state.initialWorkState}
                onLockClick={this.handleLockClick}
                onLoadClick={this.handleLoadClick}
                onUnloadClick={this.handleUnloadClick}
                onViewImageClick={this.handleViewImageClick}
            />
        };

        return (
            <Row className="dataset-browser">
                <FilterDialog
                    isVisible={this.state.showFilterModal}
                    onHide={() => this.handleShowFilterModal(false)}
                    filterText={this.state.filterText || ''}
                    isFilterCaseSensitive={this.state.filterCaseSensitive}
                    isFilterUseOriginalOn={this.state.filterUseOriginal}
                    filteredGradingStateOptions={this.state.filterGradingStateOptions}
                    onFilterTextChange={this.handleFilterTextChange}
                    onFilterCaseSensitivityChange={this.handleFilterCaseSensitiveChange}
                    onFilterUseOriginalChange={this.handleFilterUseOriginalChange}
                    onGradingStateOptionsChange={this.handleFilterGradingStatesChange}
                    roiNamesOriginal={roiNamesOriginal}
                    roiNamesStandard={roiNamesStandard}
                />

                {this.props.isUpdateTaskDialogVisible &&
                    <TasksDialog
                        isVisible={this.props.isUpdateTaskDialogVisible!}
                        fileShare={this.state.currentFileShare!}
                        onDuplicateTaskClick={this.handleOpenDuplicateTaskDialog}
                    />}
                {this.props.isTaskDescriptionDialogVisible && this.props.currentTask &&
                    <TaskDescriptionDialog
                        description={this.props.currentTask.description}
                        isVisible={this.props.isTaskDescriptionDialogVisible}
                        onClose={this.handleCloseTaskDescriptionDialog} />
                }
                {this.state.isAddTaskDialogVisible &&
                    <AddTaskDialog
                        isVisible={this.state.isAddTaskDialogVisible}
                        shareInfo={this.state.currentFileShare}
                        onClose={this.handleCloseAddTaskDialog}
                    />
                }
                {this.state.isDuplicateTaskDialogVisible && this.props.currentTask &&
                    <AddTaskDialog
                        isVisible={this.state.isDuplicateTaskDialogVisible}
                        onClose={this.handleCloseDuplicateTaskDialog}
                        shareInfo={this.state.currentFileShare}
                        duplicateFromTask={this.props.currentTask}
                        taskGroups={this.props.taskGroups}
                        loadDatasetForDuplication={this.loadDatasetForDuplication}
                    />
                }

                {showDatasetSelector ? (
                    <Col sm="2" style={{ maxWidth: '12em' }}>
                        <Card className="card-shadow" style={{ placeItems: 'center' }} >
                            <Row>
                                {this.state.storageAccountNames !== undefined ?
                                    <div>
                                        <DropdownButton data-cy="dropdown-button" className="storage-dropdown" id="storage-account-select" title={storageAccountDropdownTitle} size="sm" variant="light" >
                                            {
                                                this.state.storageAccountNames.map((saName) =>
                                                    <Dropdown.Item
                                                        as="button"
                                                        key={saName}
                                                        onClick={() => this.handleSelectStorage(saName)}
                                                        active={this.state.currentStorageAccount === saName}>{saName}</Dropdown.Item>
                                                )}
                                        </DropdownButton>
                                    </div> : <div>Loading available storage accounts...</div>}
                            </Row>
                            <Row>
                                {!!fileShares &&
                                    (<div className="file-share-select" data-cy="file-share-select">
                                        {fileShares.map((item, key) =>
                                            <div className={currentFileShare === item ? "file-share-select-item file-share-select-item-highlight" : "file-share-select-item"} key={key}
                                                onClick={() => this.handleSelectFileShare(item, true)}
                                                data-cy={`file-share-select-item`} data-cy-fileshare={item.fileShareName}>{item.fileShareName}</div>
                                        )}
                                    </div>)
                                }
                            </Row>
                        </Card>
                    </Col>
                )
                    :
                    (<Col sm="2"></Col>)
                }

                <Col sm={this.props.type === DatasetBrowserType.TrainingTaskBrowser ? '12' : '10'}>
                    <div className="dataset-div">
                        <h4>{headerText + mainHeader}</h4>
                        <h5>{this.state.patientCountHeader}</h5>

                        {isDatasetLoaded && (
                            <div className="filter-buttons">
                                {isFilterOn ?
                                    (<div>
                                        <div className="filter-text-preview">{}</div>
                                        <ButtonGroup className="mr-2">
                                            <DropdownButton className="filter-operation-dropdown" id="filter-operation-dropdown" title={operatorTitles[this.state.filterOperator]} size="sm" variant="light">
                                                <Dropdown.Item as="button" key={"OR"} onClick={() => this.setState({ filterOperator: FilterOperator.Or }, () => this.handleRefreshClick())}>{operatorTitles[FilterOperator.Or]}</Dropdown.Item>
                                                <Dropdown.Item as="button" key={"AND"} onClick={() => this.setState({ filterOperator: FilterOperator.And }, () => this.handleRefreshClick())}>{operatorTitles[FilterOperator.And]}</Dropdown.Item>
                                            </DropdownButton>
                                            <Button variant={"light"} size="sm" onClick={this.handleEditFilterClick}>{ft}</Button>
                                            <Button variant={"light"} size="sm" onClick={this.handleDeleteFilterClick}>Reset filter</Button>
                                            <Button variant={"light"} size="sm" title="Refresh dataset table" onClick={() => this.handleRefreshClick()}>Refresh</Button>
                                        </ButtonGroup>
                                    </div>) :
                                    (<div>
                                        {this.props.type === DatasetBrowserType.AnnotationBrowser &&

                                            <ButtonGroup className="mr-2">
                                                <Button variant={"light"} size="sm" title="Refresh dataset table" onClick={() => this.handleRefreshClick()}>Refresh</Button>
                                            </ButtonGroup>}
                                    </div>)}
                            </div>
                        )}
                        {currentPage}
                    </div>
                </Col>
            </Row>
        );
    }
}


export default connector(DatasetBrowser);
