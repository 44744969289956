import Immerable from "./immerable";
import { DatasetImage } from "../datasets/dataset-image";
import { Dataset } from "../datasets/dataset";
import _ from "lodash";
import { TrainingTask, isTrainingTask } from "../datasets/training-task";
import { UrlQuery, UrlQueryType } from "./url-query";

export enum Workspace {
    None = 'None',
    Home = 'Home',
    Annotation = 'Annotation',
    ReferenceLibrary = 'ReferenceLibrary',
    SupportingScan = 'SupportingScan',
    GuidelineTraining = 'GuidelineTraining',
    QATool = 'QATool',
};

/**
 * A work state models either the current "work" that is being worked on in RTViewer,
 * or the current annotation dataset index that is open on the annotation page.
 * 
 * (Currently the "work" effectively means annotation work, but in future could also
 * be something else.)
 * 
 * A work state object should be treated as immutable. If you need to modify a work
 * state object, deep clone it and modify the copy.
 * 
 * TODO: define the relationship between WorkState and ViewerState better.
 */
export default class WorkState extends Immerable {

    /** Current storage account of the dataset index, if any. */
    public readonly storageAccount: string | null;

    /** Current file share of the dataset index, if any. A file share is optional for dataset index. */
    public readonly fileShare: string | null;

    /** Current training tasks loaded into the tasks list page, if any. */
    public readonly tasks: TrainingTask[] | null;

    /** Current training task loaded into RTViewer, if any. */
    public readonly task: TrainingTask | null;

    /** Dataset (annotation) image currently loaded in RTViewer.  */
    public readonly datasetImage: DatasetImage | null;

    /** Current active annotation dataset. RTViewer can as of now only work with one dataset at a time. */
    public readonly dataset: Dataset | null;

    /** If true the user allowed to modify current work. If false the user can only view the current
    * work in read-only mode. */
    public readonly canEdit: boolean;

    /** If true the user can create new RT structure sets within current work. */
    public readonly canCreateRtstruct: boolean;

    /** ID of a structure set that is currently active in RTViewer, if any. */
    public readonly structureSetId: string | null = null;

    /** Contains an error message if the current work is in an error state for any reason, null otherwise. */
    public readonly error: string | null = null;

    /** True if this work state models a dataset index, false otherwise. */
    public readonly isDatasetIndex: boolean;

    /** Current active workspace */
    public readonly workspace: Workspace;

    constructor(
        /** Current storage account of the dataset index, if any. */
        storageAccount: string | null,
        /** Current file share of the dataset index, if any. A file share is optional for dataset index. */
        fileShare: string | null,
        /** Current active annotation dataset, if any. */
        dataset: Dataset | null,
        /** The workspace that is being opened. */
        workspace: Workspace);
    constructor(
        /** Dataset (annotation) image currently loaded in RTViewer.  */
        datasetImage: DatasetImage | null,

        /** Current active annotation dataset. RTViewer can as of now only work with one dataset at a time. */
        dataset: Dataset | null,

        /** If true the user allowed to modify current work. If false the user can only view the current
         * work in read-only mode. */
        canEdit: boolean,

        /** The workspace that is being opened. */
        workspace: Workspace,

        /** If true the user can create new RT structure sets within current work. */
        canCreateRtstruct: boolean,

        /** ID of a structure set that is currently active in RTViewer, if any. */
        structureSetId?: string | null,

        /** Contains an error message if the current work is in an error state for any reason, null otherwise. */
        error?: string | null,
    );
    constructor(
        tasks: TrainingTask[]
    );
    constructor(
        task: TrainingTask,

        /** If true the user allowed to modify current work. If false the user can only view the current
         * work in read-only mode. */
        canEdit: boolean,
    );
    constructor(
        task: TrainingTask,

        /** If true the user allowed to modify current work. If false the user can only view the current
         * work in read-only mode. */
        canEdit: boolean,

        /** Current active annotation dataset. RTViewer can as of now only work with one dataset at a time. */
        dataset: Dataset | null,

        /** Dataset (annotation) image currently loaded in RTViewer.  */
        datasetImage: DatasetImage | null,
    );
    constructor(
        a: string | DatasetImage | TrainingTask[] | TrainingTask | null,
        b?: string | Dataset | null | boolean,
        c?: Dataset | null | boolean,
        d?: Workspace | DatasetImage | null,
        canCreateRtstruct?: boolean,
        structureSetId?: string | null,
        error?: string | null,
    ) {
        super();

        this.workspace = d && !(d instanceof DatasetImage) ? d : Workspace.None;

        // TODO: Turn all of this into a configuration object instead, this overloading has become a bit unreadable
        if (a && !b && !c && _.isArray(a) && a.every(isTrainingTask)) {
            // we're creating a WorkState for an array of training tasks
            this.tasks = a;
            this.canEdit = false;
            this.task = null;
            this.workspace = Workspace.GuidelineTraining;
        } else if (isTrainingTask(a) && _.isBoolean(b)) {
            // we're creating a WorkState for a single task
            this.task = a;
            this.canEdit = b;
            this.tasks = null;
            this.workspace = Workspace.GuidelineTraining;
        } else {
            this.canEdit = _.isBoolean(c) ? c : false;
            this.task = null;
            this.tasks = null;
        }

        this.datasetImage = a instanceof DatasetImage ? a : d instanceof DatasetImage ? d : null;
        this.storageAccount = _.isString(a) ? a : null;
        this.dataset = b instanceof Dataset ? b : c instanceof Dataset ? c : null;
        this.fileShare = _.isString(b) ? b : null;
        this.canCreateRtstruct = canCreateRtstruct !== undefined ? canCreateRtstruct : false;
        this.structureSetId = structureSetId !== undefined ? structureSetId : null;
        this.error = error !== undefined ? error : null;
        this.isDatasetIndex = canCreateRtstruct === undefined;
    }

    /** 
     * Returns true if current work has a valid dataset and a valid image and is not in an error state. False otherwise.
     * Having storage account and/or fileshare defined does NOT count as having valid work.
     */
    hasAnnotationWork(): boolean {
        return this.error === null && ((this.dataset !== null && this.datasetImage !== null));
    }

    /**
     * Returns true if current work state links to a dataset index, e.g. it has storage account and optionally
     * file share defined.
     */
    hasDatasetIndex(): boolean {
        return this.isDatasetIndex && (this.storageAccount !== null || this.tasks !== null);
    }

    /** 
     * Returns true if current work has a task and is not in an error state. False otherwise.
     */
    hasTaskWork(): boolean {
        return this.error === null && ((this.task !== null));
    }

    /** Returns true if current work has a valid active structure set, false otherwise. */
    hasStructureSet(): boolean {
        return !!this.structureSetId || (!!this.task && !!this.task.traineeStructureSet.sopInstanceUid);
    }

    /** Returns true if current work is in an error state, false otherwise. */
    hasError(): boolean {
        return this.error !== null;
    }

    /** Returns an error message if current work is in an error state, or an empty string if there is no current error.  */
    getErrorMessage(): string {
        return this.error || '';
    }

    /** Returns a short-hand name for the current work. */
    getWorkName(): string | undefined {
        if (this.dataset && this.datasetImage) {
            // return `${this.dataset.datasetFile.getShare().toString()}/${this.datasetImage.patientId}/${this.datasetImage.seriesDescription}`;
            return this.datasetImage.patientId;
        } else if (this.hasTaskWork()) {
            // consider returning task name instead
            return this.task!.name || this.task!.patientId;
        }

        return undefined;
    }

    /** Returns an annotation query matching current work. This will identify the current work uniquely to a certain degree -- enough to generate
     * a hyperlink, but not enough to replicate e.g. unsaved data, undo stacks, or used annotation filters. */
    getAnnotationQuery(): UrlQuery | undefined {
        if (this.hasAnnotationWork()) {
            return new UrlQuery(
                UrlQueryType.AnnotationWorkQuery,
                this.dataset!.datasetFile.storageAccountName,
                this.dataset!.datasetFile.fileShareName,
                this.datasetImage!.patientId,
                this.datasetImage!.frameOfReferenceUid,
                this.datasetImage!.seriesId,
                this.structureSetId || undefined,
            )
        } else if (this.hasDatasetIndex()) {
            return new UrlQuery(UrlQueryType.AnnotationIndexQuery, this.storageAccount!, this.fileShare || undefined);
        }

        return undefined;
    }

    /** Returns a task query matching current task work. */
    getTaskQuery(): UrlQuery | undefined {
        if (this.hasTaskWork()) {
            return new UrlQuery(UrlQueryType.TaskWorkQuery, this.task!.id);
        }
    }
}
