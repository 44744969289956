import React from 'react';
import { Item, Submenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import './RoiTypeMenu.css';
import { Roi } from '../../dicom/structure-set';
import { RoiGuidelines, ContouringGuideline } from '../../web-apis/rtviewer-api-client';

interface OwnProps {
    roi: Roi | null;
    guidelines?: RoiGuidelines;
}

/**
 * Sub menu for displaying and changing a structure's ROIInterpretedType value.
 * Must be used within a react-contexify menu.
 */
export default class RoiGuidelineMenu extends React.PureComponent<OwnProps> {
    private handleGuidanceClick = (guideline: ContouringGuideline) => {
        if (guideline.link) {
            window.open(guideline.link, "_blank");
        } else if (guideline.text) {
            // If there's text but no link, show an alert with the reference
            alert(`Reference: ${guideline.text}`);
        }
    }

    render() {
        const { roi, guidelines } = this.props;

        if (!roi || !guidelines || guidelines.length === 0) {
            return (
                <Submenu label={(<span>Guidelines</span>)}>
                    <Item disabled={true}>No guidelines available</Item>
                </Submenu>
            );
        }

        // Remove "L:" prefix if present
        const roiName = roi.name.startsWith("L:") ? roi.name.substring(2) : roi.name;

        const guidelinesMatchingCurrentRoi = guidelines.filter(r =>
            r.roiName.toLocaleLowerCase() === roiName.toLocaleLowerCase()
        );

        const roiGuidelines = guidelinesMatchingCurrentRoi.length > 0
            ? guidelinesMatchingCurrentRoi[0].contouringGuidelines
            : undefined;

        return (
            <Submenu label={(<span>Guidelines</span>)}>
                {roiGuidelines && roiGuidelines.length > 0 ? (
                    roiGuidelines.map(r => (
                        <Item
                            key={r.text}
                            data={r}
                            onClick={() => this.handleGuidanceClick(r)}
                            disabled={!r.link}
                        >
                            {r.text || 'No description available'}
                        </Item>
                    ))
                ) : (
                    <Item disabled={true}>No guidelines available for this structure</Item>
                )}
            </Submenu>
        );
    }
}