import React from 'react';
import { Button, Dropdown, Form, Badge } from 'react-bootstrap';
import { HiViewColumns } from "react-icons/hi2";

import { MetricLabels, Metric, AllMetricsInOrder } from '../../web-apis/similarity-metrics';

import './SimilarityMetricsSelector.css';

type SimilarityMetricsSelectorProps = {
    visibleMetrics: { [m in Metric]: boolean },
    onMetricVisibilityToggled: (metric: Metric) => void,
    embed?: boolean,
}

type SimilarityMetricsSelectorState = {
    /** Whether the selector dropdown is open or not ("shown") */
    isOpen: boolean,
}

class SimilarityMetricsSelector extends React.Component<SimilarityMetricsSelectorProps, SimilarityMetricsSelectorState>{

    constructor(props: SimilarityMetricsSelectorProps) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    handleMetricsDropdownToggle = (
        isOpen: boolean,
        event: React.SyntheticEvent<Dropdown<"div">, Event>,
        metadata: { source: "select" | "click" | "rootClose" | "keydown"; }
    ) => {
        // don't close dropdown when user clicks on items on it (only allow it to close
        // when user clicks outside of the component), otherwise allow default behaviour
        if (!isOpen && (metadata.source === "select" || metadata.source === "keydown")) {
            return;
        }

        this.setState({ isOpen: isOpen });
    }

    handleToggleMetricVisibility = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>, metric: Metric) => {
        evt.nativeEvent.preventDefault();
        this.props.onMetricVisibilityToggled(metric);
    }

    render() {
        const { visibleMetrics, embed } = this.props;

        const currentVisibleMetricsCount = Object.values(visibleMetrics).filter(v => v === true).length;

        return (
            <div className={`metrics-selector ${embed ? 'embedded' : ''}`}>
                <Dropdown show={this.state.isOpen} onToggle={this.handleMetricsDropdownToggle}>
                    <Dropdown.Toggle as={Button} id="metrics-selector-toggle-button">
                        <span className="metrics-selector-label">
                            <span className="metrics-selector-text">Select Metrics</span>
                            <span className="metrics-selector-badge"><Badge variant="light">{currentVisibleMetricsCount}</Badge></span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {AllMetricsInOrder.map(m => (
                            <Dropdown.Item
                                key={m}
                                eventKey={m}
                                onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMetricVisibility(evt, m)}
                                className="metrics-selector-item"
                            >
                                <Form.Check
                                    key={Math.random()}
                                    type="checkbox"
                                    label={MetricLabels[m]}
                                    defaultChecked={visibleMetrics[m]}
                                    onClick={(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => this.handleToggleMetricVisibility(evt, m)}
                                    onChange={() => { /* already handled in onClick */ }}
                                />
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div >
        );
    }
}

export default SimilarityMetricsSelector;
