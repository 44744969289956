import _ from "lodash";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaPlus, FaTimesCircle, FaExclamationTriangle } from "react-icons/fa";
import { connect } from "react-redux";
import Select from 'react-select';
import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import AppAuth from "../../../web-apis/app-auth";

import { AzureShareInfo } from "../../../web-apis/azure-files";
import { Backend } from "../../../web-apis/backends";
import ModalDialog from "../ModalDialog";
import "./AddTaskDialog.css";
import { PageImage, PageStructureSet } from "../../annotation-page/models/PagePatient";
import { DatasetStructureSet } from "../../../datasets/dataset-structure-set";
import SimilarityMetricsTable from "./roi-settings/SimilarityMetricsTable";
import { TrainingTaskRoi, createBatchTasks, CalculatedMetrics, TrainingTask, TrainingTaskGroup } from "../../../datasets/training-task";
import { produce } from "immer";
import { SessionNotification, NotificationType, DEFAULT_SESSION_TIMEOUT_IN_MS } from "../models/SessionNotification";
import { TrainingUser } from "../../../store/training-user";
import { sortAlphanumeric } from "../../../util";

const TASK_NAME_LENGTH_MIN = 4;
const TASK_NAME_LENGTH_MAX = 60;


/** Returns true if the requested user was assigned a version of the task we're duplicating, false otherwise.
 * NOTE: does NOT check in archived tasks!
 */
const wasDuplicateeTaskAssignedToUser = (previouslyAssignedTraineeUserIds: string[], userId: string): boolean => {
    return previouslyAssignedTraineeUserIds.includes(userId);
}


type OwnProps = {
    isVisible: boolean,
    shareInfo: AzureShareInfo | undefined,
    duplicateFromTask?: TrainingTask,
    taskGroups?: TrainingTaskGroup[],
    onClose: () => void,
    loadDatasetForDuplication?: (azureShare: AzureShareInfo) => void,
}

export enum NewStructureSetOption {
    NoSelection, FromScratch, AutoContour
}

type DispatchProps = {
    setUserSettingBackend(backend: Backend | null): void,
    logIntoBackend(backend: Backend | null): void,
    logIntoAppAuth: (appAuth: AppAuth) => void,
    requestLogOut: () => void,
    setUserSettingPatientInfoVisibility(showPatientInfo: boolean): void,
    downloadDataset: (azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) => void,
    reloadTasks: (azureShare: AzureShareInfo) => void,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
}

type OwnState = {
    pageStructureSets: PageStructureSet[],
    roiNames: string[],
    selectedRoiNames: string[],
    acceptanceCriteria: TrainingTaskRoi[],
    selectedPatients: string[],
    taskName: string,
    taskDescription: string,
    selectedResidents: TrainingUser[],
    type: string,
    isDuplicating: boolean,
    duplicateShareInfo: AzureShareInfo | undefined,
    previouslyAssignedTraineeUserIds: string[],
}


type AllProps = OwnProps & StoreState & DispatchProps;

class AddTasksDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            pageStructureSets: [],
            roiNames: [],
            selectedRoiNames: [],
            acceptanceCriteria: [],
            selectedResidents: [],
            selectedPatients: [],
            taskName: "",
            taskDescription: "",
            type: "",
            isDuplicating: false,
            duplicateShareInfo: undefined,
            previouslyAssignedTraineeUserIds: []
        };
    }

    componentDidMount = () => {
        if (this.props.duplicateFromTask !== undefined) {
            this.initializeFromExistingTask(this.props.duplicateFromTask, this.props.taskGroups ? this.props.taskGroups : []);
        }
    }

    componentDidUpdate = (prevProps: AllProps, prevState: OwnState) => {
        // pre-configure dialog if we have a task duplicatee set
        if (this.props.duplicateFromTask !== undefined && this.props.duplicateFromTask !== prevProps.duplicateFromTask) {
            this.initializeFromExistingTask(this.props.duplicateFromTask, this.props.taskGroups ? this.props.taskGroups : []);
        }
    }

    initializeFromExistingTask = (task: TrainingTask, groups: TrainingTaskGroup[]) => {

        if (this.props.loadDatasetForDuplication === undefined) {
            throw new Error('Duplication code not initialized properly');
        }

        // find similar-enough tasks
        const group = groups.find(g => g.name === task.name);
        if (!group) {
            throw new Error(`Could not find matching task group for task '${task.name}'`);
        }

        const azureShareInfo = new AzureShareInfo(task.storageAccount, task.fileShare);

        this.props.loadDatasetForDuplication(azureShareInfo);

        this.setState({
            taskName: task.name,
            taskDescription: task.description,
            type: task.type,
            selectedPatients: _.uniq(group.trainingTasks.map(t => t.patientId)),
            acceptanceCriteria: task.gtStructureSet.rois,
            selectedRoiNames: task.traineeStructureSet.rois.map(r => r.roiName),
            isDuplicating: true,
            duplicateShareInfo: azureShareInfo,
            previouslyAssignedTraineeUserIds: group.trainingTasks.map(t => t.trainee.user_id),
        });
    }

    /** Returns undefined if form is valid, or an array of validation error messages. */
    getFormValidationErrors = (): undefined | string[] => {
        const errorMessages: string[] = [];

        if (!this.state.taskName) {
            errorMessages.push('no task name set');
        } else if (this.state.taskName.length < TASK_NAME_LENGTH_MIN) {
            errorMessages.push(`task name must be ${TASK_NAME_LENGTH_MIN} characters or longer`);
        }

        if (!this.state.type) {
            errorMessages.push('no task type set');
        }

        if (this.state.acceptanceCriteria.length === 0) {
            errorMessages.push('no structures selected');
        }

        if (this.state.selectedPatients.length === 0) {
            errorMessages.push('no scans selected');
        }

        if (this.state.selectedResidents.length === 0) {
            errorMessages.push('no trainees selected');
        }

        return errorMessages.length === 0 ? undefined : errorMessages;
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleRoisChange = (option: any) => {
        // if option.value already exists in selectedRois array, remove it
        let selectedRois: string[] = [];
        if (this.state.selectedRoiNames.includes(option.value)) {
            selectedRois = this.state.selectedRoiNames.filter(s => s !== option.value);
        } else {
            selectedRois = [...this.state.selectedRoiNames, option.value];
        }

        // map selected rois to acceptance criteria name leaving the rest of the fields empty
        const acceptanceCriteria: TrainingTaskRoi[] = selectedRois.map((roiName, i) => {
            const existingAcceptanceCriteria = _.cloneDeep(this.state.acceptanceCriteria.find(ac => ac.roiName === roiName));
            if (existingAcceptanceCriteria) {
                existingAcceptanceCriteria.roiIdx = i;
                return existingAcceptanceCriteria;
            }

            return {
                roiName: roiName,
                roiIdx: i,
                description: '',
                grade: '',
                acceptanceCriteria: {}
            }
        });

        this.setState({ selectedRoiNames: selectedRois, acceptanceCriteria });
    }

    handleResidentChange = (option: any) => {
        // if resident already exists in selectedResidents array, remove it
        if (this.state.selectedResidents.includes(option.value)) {
            this.setState({ selectedResidents: this.state.selectedResidents.filter(s => s !== option!.value) });
        } else {
            this.setState({ selectedResidents: [...this.state.selectedResidents, option!.value] });
        }
    }

    handlePatientsChange = (option: any) => {
        // if option.label already exists in selectedResidents array, remove it
        if (this.state.selectedPatients.includes(option.label)) {
            this.setState({ selectedPatients: this.state.selectedPatients.filter(s => s !== option.label) });
        } else {
            this.setState({ selectedPatients: [...this.state.selectedPatients, option.label] });
        }
    }

    handleChangeAcceptanceCriteria = (roiToUpdate: string, updates: Partial<CalculatedMetrics>) => {
        this.setState(produce((draft: OwnState) => {
            const matchingACRoiIndex = draft.acceptanceCriteria.findIndex(r => r.roiName === roiToUpdate);
            if (matchingACRoiIndex === -1) {
                throw new Error(`Could not find structure ${roiToUpdate} from acceptance criteria!`);
            }
            draft.acceptanceCriteria[matchingACRoiIndex].acceptanceCriteria = Object.assign({}, { ...draft.acceptanceCriteria[matchingACRoiIndex].acceptanceCriteria, ...updates });
        }));
    }

    // handle submit of form to create new task
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.props.trainingUsers === undefined) { throw new Error('Users collection not initialized'); }

        const fileShare = this.state.isDuplicating ? this.state.duplicateShareInfo : this.props.shareInfo;

        if (!fileShare) {
            throw new Error('Undefined fileshare');
        }

        const tasks = {
            storageAccount: fileShare.storageAccountName,
            fileShare: fileShare.fileShareName,
            name: this.state.taskName,
            description: this.state.taskDescription,
            type: this.state.type,
            supervisor: {
                user_id: this.props.user!.userId,
                user_name: this.props.user!.username,
            },
            patientIds: this.state.selectedPatients,
            trainees: this.state.selectedResidents.map(r => ({ user_id: r.userId, user_name: r.userName })),
            rois: this.state.acceptanceCriteria
        };

        const multipleTasks = tasks.patientIds.length > 1 || tasks.trainees.length > 1;

        createBatchTasks(tasks)
            .then(() => {
                this.props.onClose();
                this.props.reloadTasks(fileShare);
                const notificationId = `task-added-${Date.now().toString()}`;
                this.props.addNotification(new SessionNotification(notificationId,
                    multipleTasks ? 'Tasks were created successfully.' : 'Task was created successfully.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS));
            })
            .catch(error => {
                const message = multipleTasks ? 'An error occurred while trying to create tasks.' : 'An error occurred while trying to create task.';
                const errorDetail = _.isError(error) ? error.message : undefined;
                console.error(message);
                console.error(error);
                const notificationId = `task-failed-to-add-${Date.now().toString()}`;
                this.props.addNotification(new SessionNotification(notificationId, message, NotificationType.Failure, errorDetail));
            });

        this.handleClose();
    };

    handleChange = (option: any) => {
        const value: PageImage = option.value[0];
        const ss = value.structureSets;
        this.setState({ pageStructureSets: ss });
    }

    handlePageStructChange = (option: any) => {
        const ss: DatasetStructureSet = option.value;
        // get the name of the rois coming from the Roimapping from ss and put them all in an array of strings
        const rois: string[] = ss.roiMappings.map(r => r.originalName);
        this.setState({ roiNames: rois });
    }

    render() {
        const isVisible = this.props.isVisible;
        const { gtRois, } = this.props;
        if (!gtRois) {
            return null;
        }
        // filter out landmark structures since they are not valid targets for acceptance criteria 
        const filteredAcceptanceCriteria = this.state.acceptanceCriteria.filter(r => !r.roiName.startsWith('L:'))

        const validationErrors = this.getFormValidationErrors();
        const isFormValid = validationErrors === undefined;
        let validationTooltip: string | undefined = undefined;
        if (validationErrors !== undefined) {
            validationTooltip = 'Form has validation errors: ' + validationErrors.join(', ');
        }

        const patientOptions = this.props.patients.map((patient: any) => {
            return {
                label: patient,
                value: patient,
            }
            // if patients is in selected patients, then erase it from the list
        }).filter((patient: any) => {
            return !this.state.selectedPatients.includes(patient.label)
        }).sort((a: { label: string; }, b: { label: string; }) => sortAlphanumeric(a.label, b.label));

        const roiOptions = gtRois.map((roi: string) => {
            return {
                label: roi,
                value: roi,
            }
            // if Roi is in selected Rois, then erase it from the list
        }).filter((roi: any) => {
            return !this.state.selectedRoiNames.includes(roi.value);
        }).sort((a, b) => sortAlphanumeric(a.label, b.label));

        const pageStructsOptions = this.state.pageStructureSets.map((structureSet: PageStructureSet) => {
            return {
                label: structureSet.structureSet.label,
                value: structureSet.structureSet,
            }
        }).sort((a, b) => sortAlphanumeric(a.label, b.label)).flat();

        const users = !this.props.trainingUsers ? [] : this.props.trainingUsers.map(u => {
            return {
                label: u.userName,
                value: u,
            }
            // if user is in selected users, then erase it from the list
        }).filter(user => !this.state.selectedResidents.includes(user.value)).sort((a, b) => sortAlphanumeric(a.label, b.label));

        const wasPreviousTaskAssigned = (residentId: string): boolean => {
            const { duplicateFromTask, taskGroups } = this.props;

            // find task group (if one exists)
            const group = !!taskGroups && duplicateFromTask ? taskGroups.find(g => g.name === duplicateFromTask.name) : undefined;

            // return false if we're not duplicating
            if (!this.state.isDuplicating || !duplicateFromTask) { return false; }

            // return false if user wasn't assigned to the original task or
            // current form state looks different from duplicatee task.
            // these checks go in order of increasing complexity so we can try to exit early
            return wasDuplicateeTaskAssignedToUser(this.state.previouslyAssignedTraineeUserIds, residentId) && (!!group &&
                !!this.state.duplicateShareInfo &&
                this.state.taskName === duplicateFromTask.name &&
                // this.state.taskDescription === duplicateFromTask.description && // ignore description from this check
                this.state.type === duplicateFromTask.type &&
                this.state.duplicateShareInfo.storageAccountName === duplicateFromTask.storageAccount &&
                this.state.duplicateShareInfo.fileShareName === duplicateFromTask.fileShare &&
                this.state.selectedRoiNames.length === duplicateFromTask.traineeStructureSet.rois.length &&
                group.trainingTasks.map(t => t.patientId).every(pId => this.state.selectedPatients.includes(pId)) &&
                duplicateFromTask.traineeStructureSet.rois.map(r => r.roiName).every(rName => this.state.selectedRoiNames.includes(rName)) &&
                // last one: check acceptance criteria here -- this can get a bit slower, depending on size of AC
                duplicateFromTask.gtStructureSet.rois.map(r => r.acceptanceCriteria).every(dac => this.state.acceptanceCriteria.map(ac => ac.acceptanceCriteria).some(ac => _.isEqual(ac, dac)))
            );
        }

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                backdrop="static"
                size="lg"
                data-cy="create-task-modal">

                <Modal.Header closeButton>
                    <Modal.Title>{this.state.isDuplicating ? (<>Duplicate Task</>) : (<>Create a New Task</>)}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body className="user-settings">

                        <div className="notice">
                            <p>Setup structure sets and structures for the new task.</p>
                            {this.state.isDuplicating && (<p>Settings from previous task are used by default but can be changed.</p>)}
                        </div>

                        {/* get tasks from store and use a select with the task uid of each one */}
                        <Form.Group className="mb-small" as={Row} controlId="formPatientId">
                            <Form.Label column className="mb-small">
                                <div>Task Name:</div>
                            </Form.Label>
                            <Col className="mb-small">
                                <Form.Control
                                    type="text"
                                    minLength={TASK_NAME_LENGTH_MIN}
                                    maxLength={TASK_NAME_LENGTH_MAX}
                                    placeholder="Task Name"
                                    value={this.state.taskName}
                                    onChange={(e: any) => this.setState({ taskName: e.target.value })}
                                    data-cy="task-modal-name"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-small" as={Row} controlId="formDescription">
                            <Form.Label column className="mb-small">
                                <div>Description (optional):</div>
                            </Form.Label>
                            <Col className="mb-small">
                                <textarea
                                    className="form-control"
                                    placeholder="Description"
                                    value={this.state.taskDescription}
                                    onChange={(e: any) => this.setState({ taskDescription: e.target.value })}
                                    data-cy="task-modal-descr"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-small" as={Row} controlId="formType">
                            <Form.Label column className="mb-small">
                                <div>Type:</div>
                            </Form.Label>
                            <Col className="value-row-shift-dropdown mb-small" data-cy="task-modal-type">
                                <Select
                                    placeholder="Type"
                                    aria-label="Type"
                                    className="select-input"
                                    options={[{ label: "Test", value: "TEST" }, { label: "Practice", value: "PRACTICE" }]}
                                    name="type"
                                    onChange={(e: any) => this.setState({ type: e.value })}
                                    data-cy="task-modal-type-options"
                                    id="task-modal-type-options"
                                    value={this.state.type === 'TEST' ? { label: "Test", value: "TEST" } : this.state.type === 'PRACTICE' ? { label: "Practice", value: "PRACTICE" } : undefined}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-small" as={Row} controlId="formRois">
                            <Form.Label column className="mb-small">
                                <div>Select structures for task:</div>
                            </Form.Label>
                            <Col className="mb-small" data-cy="task-modal-structures">
                                <Select
                                    placeholder="Select structures"
                                    aria-label="Select structures"
                                    className="select-input"
                                    options={roiOptions}
                                    onChange={this.handleRoisChange}
                                />

                            </Col>
                        </Form.Group>
                        {this.state.selectedRoiNames.length > 0 && (
                            <Form.Group className="mb-small" as={Row} controlId="formSelectedRois">
                                <Form.Label column className="mb-small">
                                    <div>Selected structures</div>
                                </Form.Label>
                                <Col className="mb-small">
                                    <div className="rois-div">
                                        {this.state.selectedRoiNames.map(roiName => (
                                            <div key={roiName} className="roi-div"><p className="roi-p">{roiName}<FaTimesCircle className="times" onClick={() => this.handleRoisChange({ label: roiName, value: roiName })} /></p></div>
                                        ))}
                                    </div>
                                </Col>
                            </Form.Group>
                        )}
                        <Form.Group className="mb-small" as={Row} controlId="formPatients">
                            <Form.Label column className="mb-small">
                                <div>Scan(s):</div>
                            </Form.Label>
                            <Col className="value-row-shift-dropdown mb-small" data-cy="task-modal-scans">
                                <Select
                                    placeholder="Scan"
                                    className="select-input"
                                    options={patientOptions}
                                    onChange={this.handlePatientsChange}
                                />
                            </Col>
                        </Form.Group>
                        {this.state.selectedPatients.length > 0 &&
                            <Form.Group className="mb-small" as={Row} controlId="formPatientsSelected">
                                <Form.Label column className="mb-small">
                                    <div>Scan(s) Selected:</div>
                                </Form.Label>
                                <Col className="mb-small">
                                    <div className="rois-div">
                                        {this.state.selectedPatients.map(patient => (
                                            <div className="roi-div" key={patient}>
                                                <p className="roi-p">
                                                    {patient}
                                                    <FaTimesCircle
                                                        className="times"
                                                        onClick={() => this.handlePatientsChange({
                                                            label: patient,
                                                            value: patient
                                                        })}
                                                    />
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                        {this.state.pageStructureSets.length > 0 &&
                            <Form.Group className="mb-small" as={Row} controlId="formTraineeStructureSet">
                                <Form.Label column className="mb-small">
                                    <div>Select reference for patient:</div>
                                </Form.Label>
                                <Col className="value-row-shift-dropdown mb-small">
                                    <Select
                                        placeholder="Trainee Structure Set"
                                        options={pageStructsOptions}
                                        className="select-input"
                                        onChange={this.handlePageStructChange}
                                    />
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group className="mb-small" as={Row} controlId="formResidentId">
                            <Form.Label column className="mb-small">
                                <div>Trainee(s):</div>
                            </Form.Label>
                            <Col className="mb-small" data-cy="task-modal-trainees">
                                <Select
                                    placeholder="Trainee"
                                    options={users}
                                    className="select-input"
                                    onChange={this.handleResidentChange}
                                />
                            </Col>
                        </Form.Group>
                        {this.state.selectedResidents.length > 0 &&
                            <Form.Group className="mb-small" as={Row} controlId="formResidentsSelected">
                                <Form.Label column className="mb-small">
                                    <div>Trainee(s) selected:</div>
                                </Form.Label>
                                <Col className="mb-small">
                                    <div className="rois-div">
                                        {this.state.selectedResidents.map(resident => (
                                            <div className={`roi-div ${wasPreviousTaskAssigned(resident.id) ? 'resident-already-included' : ''}`}
                                                key={resident.id}
                                                title={wasPreviousTaskAssigned(resident.id) ? 'This task is already assigned to this trainee (but you can assign it again if you wish)' : undefined}
                                            >
                                                <p className="roi-p">{resident.userName}</p>
                                                {wasPreviousTaskAssigned(resident.id) && (<FaExclamationTriangle className="task-prev-assigned-notification" />)}
                                                <FaTimesCircle className="times" onClick={() => this.handleResidentChange({ label: resident.userName, value: resident })} />

                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Form.Group>
                        }

                        <hr />
                        <Form.Group>
                            <Modal.Title>Test acceptance criteria</Modal.Title>
                            <div>Set acceptance criteria for structures in the task (optional):</div>
                            <br />
                            {(filteredAcceptanceCriteria.length > 0) && (
                                <SimilarityMetricsTable
                                    taskAcceptanceCriteria={filteredAcceptanceCriteria}
                                    changeAcceptanceCriteria={this.handleChangeAcceptanceCriteria}
                                    task={this.props.duplicateFromTask}
                                />
                            )}


                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" type="submit" data-cy="create-task-modal-submit" disabled={!isFormValid} title={validationTooltip}><FaPlus />{this.state.isDuplicating ? (<> Duplicate task</>) : (<> Create task</>)}</Button>
                        <Button variant="light" onClick={this.handleClose} data-cy="create-task-modal-cancel">Cancel</Button>

                    </Modal.Footer>
                </Form>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(AddTasksDialog);
