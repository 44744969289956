import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { IoMdEye } from 'react-icons/io';
import { Link } from 'react-router-dom';

import * as sagas from '../../../store/sagas';
import { StoreState } from '../../../store/store';
import './DifferencesToolbar.css';
import { selectSupportingScansForCurrentWorkState } from '../../../store/selectors';
import routePaths from '../../../routes';
import { UrlQuery, UrlQueryType } from '../../../store/url-query';

import './SupportingScansToolbar.css';


type OwnProps = {
}

type DispatchProps = {
}

// combine redux & selector props
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => Object.assign({}, state,
    {
        supportingScans: selectSupportingScansForCurrentWorkState(state),
    });
const connector = connect(mapStateToProps, sagas.mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = OwnProps & StoreState & DispatchProps & ReduxProps;

class SupportingScansToolbar extends React.PureComponent<AllProps> {
    displayName = SupportingScansToolbar.name

    render() {
        const { supportingScans } = this.props;

        // all supporting scans should be from the same dataset!
        const dataset = this.props.currentWorkState!.dataset;
        if (!dataset) {
            throw new Error('No valid dataset');
        }

        return (
            <Row className='task-details-tab'>
                <Col style={{ display: "flex" }}>
                    <span className="supporting-scans-header"><h5>Supporting Scans</h5></span>

                    {supportingScans.map((scan, index) => (
                        <span key={scan.scanId} className="supporting-scan-link">
                            <Link
                                target="_blank" rel="noopener noreferrer"
                                to={{
                                    pathname: routePaths.supportingScan,
                                    search: new UrlQuery(
                                        UrlQueryType.SupportingScanQuery,
                                        dataset.datasetFile.storageAccountName,
                                        dataset.datasetFile.fileShareName,
                                        scan.patientId,
                                        scan.frameOfReferenceUid,
                                        scan.seriesId
                                    ).getQueryParameters(UrlQueryType.SupportingScanQuery),
                                }}>
                                <Button
                                    className='view-button'
                                    variant={"light"}
                                    size="sm"
                                    title="Open this supporting scan in a new tab">
                                    <span><IoMdEye size={20} className="supporting-scan-icon" /><span>#{index + 1} ({scan.modality})</span></span>
                                </Button>
                            </Link>
                        </span>
                    ))}
                </Col>

            </Row >
        );
    }
}

export default connector(SupportingScansToolbar);
