import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
    grade?: string;
    onGradeSelect: (grade: number) => void;
};

class GradeButtons extends React.Component<Props> {
    handleGradeClick = (grade: number) => {
        this.props.onGradeSelect(grade);
    };

    render() {
        const gradeList = [1, 2, 3, 4, 5];
        const selectedGrade = this.props.grade ? parseInt(this.props.grade) : null;

        return (
            <div>
                Low
                {" "}
                {gradeList.map((grade) => (
                    <Button
                        key={grade}
                        variant={selectedGrade === grade ? 'success' : 'secondary'}
                        className={selectedGrade === grade ? 'selected-grade mr-2 mb-2' : 'mr-2 mb-2'}
                        onClick={() => this.handleGradeClick(grade)}
                    >
                        {grade}
                    </Button>
                ))}
                High
            </div>
        );
    }
}

export default GradeButtons;