import { Configuration, PublicClientApplication, BrowserCacheLocation, AuthenticationResult } from "@azure/msal-browser";
import _ from "lodash";
import { MVISION_AUTHORITY, getClientId, getAppName, RTViewerDisplayVersion } from "../environments";
import { AppVersionInfo } from "../store/app-version-info";
import { sleep, timeoutSignal } from "../util";
import { getLatestActiveMsalAccount, setLatestActiveMsalAccount } from "../local-storage";

export const POPUPS_BLOCKED_ERROR = 'POPUPS_BLOCKED_ERROR';

export const QUERY_PARAM_CLIENT_ID = 'clientId';

const VERBOSE_LOGGING = false;

const POPUP_REDIRECT_URI = '/loggedIn.html';
const LOGOUT_REDIRECT_URI = window.location.origin + '/logged-out';

/** Details of a user after they have authenticated through MSAL. */
export type LoggedInMsalUser = {
    userId: string;
    name?: string;
    email?: string;
};

export class BackendFetchOptions {
    /** Allow caching of request response. Default: false. */
    allowCache: boolean;

    /** Return quickFetch response as text instead of json. Default: false. Only applicable to quickFetch. */
    asText: boolean;

    /** Don't append client ID to URL query parameters. Default: false. */
    noClientId: boolean;

    /** The time to wait during a fetch before timing it out. Using undefined uses browser default. Default: undefined. */
    fetchTimeoutInMs: number | undefined;

    /** Maximum number of retries to perform if a call fails. */
    maxRetries: number;

    /** The time in milliseconds to wait before a retry. */
    retryWaitInMs: number;

    constructor(values: Partial<BackendFetchOptions>) {
        this.allowCache = _.get(values, 'allowCache', false);
        this.asText = _.get(values, 'asText', false);
        this.noClientId = _.get(values, 'noClientId', false);
        this.fetchTimeoutInMs = _.get(values, 'fetchTimeoutInMs', undefined);
        this.maxRetries = _.get(values, 'maxRetries', 20);
        this.retryWaitInMs = _.get(values, 'retryWaitInMs', 10 * 1000); // 10 seconds
    }
}

// this class models authentication to an azure app registration
export default class AppAuth {

    // name of the app registration
    appName: string;

    // client ID matching the azure app registration
    clientId: string;

    config: Configuration;

    // the set of scopes that we ask for when requesting tokens
    request: any;

    msalInstance: PublicClientApplication | null;

    // true if user has logged into this app registration, false otherwise
    isLoggedIn: boolean;

    /** The version of the app that this app auth is sending requests for. */
    appVersion: AppVersionInfo | undefined;

    constructor(appName: string, clientId: string) {
        this.appName = appName;
        this.clientId = clientId;

        this.isLoggedIn = false;
        this.appVersion = undefined;

        this.request = {
            scopes: [`${clientId}/.default`],
            // uncomment some combination of the lines below to test immediately expiring tokens
            // maxAge: 1,
            // forceRefresh: true,
            // cacheLookupPolicy: CacheLookupPolicy.Skip, 
        };

        this.config = {
            auth: {
                clientId: this.clientId,
                authority: MVISION_AUTHORITY,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
            },
            // system: {
            //         // uncomment this if needed
            //         loggerOptions: {
            //             loggerCallback: (logLevel: LogLevel, message: string, containsPii: boolean) => console.log(`${this.appName}: ${message}`),
            //             logLevel: LogLevel.Info,
            //             // change this to true if needed -- don't commit or deploy into production
            //             piiLoggingEnabled: false,
            //         },
            //     // tokenRenewalOffsetSeconds: 60 * 60
            // },
        };

        this.msalInstance = null;
    }

    setAppVersion(appVersion: AppVersionInfo) {
        this.appVersion = appVersion;
    }

    private verboseLog(message: string) {
        if (VERBOSE_LOGGING) { console.log(message); }
    }

    private setLatestActiveAccount() {
        if (this.msalInstance) {
            const account = this.msalInstance.getActiveAccount();
            if (account) {
                this.verboseLog('Setting latest active MSAL account');
                setLatestActiveMsalAccount(account.homeAccountId);
                return;
            }
        }

        throw new Error('Could not set latest MSAL account');
    }

    /** Log in using POPUP flow. */
    async logIn() {
        if (this.msalInstance === null) { this.msalInstance = new PublicClientApplication(this.config); }

        // we need to handle redirect promise here ALWAYS in case we're dealing with a cancelled log-out process
        await this.msalInstance.handleRedirectPromise();

        if (!this.isLoggedIn) {

            /** Try getting an access token to check that we're ACTUALLY logged in */
            const tryGettingAccessTokenAndSetLoggedIn = async () => {
                this.verboseLog('Getting an access token with cached credentials');
                await this.getAccessToken();
                this.setLatestActiveAccount();
                this.isLoggedIn = true;
            };

            // First see if we already have exactly one set of cached credentials
            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length === 1) {
                this.verboseLog('Already logged in, using cached credentials');
                this.msalInstance.setActiveAccount(accounts[0]);

                try {
                    await tryGettingAccessTokenAndSetLoggedIn();
                    return;
                } catch (err) {
                    this.verboseLog('Did not get access token with cached credentials');
                    console.error(err);
                }
            }

            // If not, try to see if we already have an active account
            const latestAccount = getLatestActiveMsalAccount();
            if (latestAccount) {
                this.verboseLog('Found latest active MSAL account entry -- checking if that\'s still logged in');

                try {
                    const account = this.msalInstance.getAccountByHomeId(latestAccount);
                    if (account) {
                        this.verboseLog('Trying to use an existing active account');
                        this.msalInstance.setActiveAccount(account);
                        await tryGettingAccessTokenAndSetLoggedIn();
                        return;
                    }
                }
                catch (err) {
                    this.verboseLog('Did not get access token with existing active account');
                    console.log(err);
                }
            }

            // Fall back to regular popup auth login flow
            try {
                this.verboseLog('Falling back to regular popup auth login');
                const authResult = await this.msalInstance.loginPopup({
                    scopes: this.request.scopes,
                    redirectUri: POPUP_REDIRECT_URI,
                    prompt: 'select_account'
                });
                if (authResult.account === null) {
                    throw new Error('No valid account after login');
                }
                this.msalInstance.setActiveAccount(authResult.account);
                this.setLatestActiveAccount();
            }
            catch (err) {
                console.error(err);
                if ('errorCode' in err && err.errorCode.includes('popup_window_error')) {
                    throw new Error(POPUPS_BLOCKED_ERROR);
                } else if ('errorCode' in err && (err.errorCode.includes('block_nested_popups'))) {
                    // ignore block_nested_popups errors to prevent visible error dialogs showing up on
                    // login popup windows
                }
                else throw err;
            }
        }

        this.isLoggedIn = true;
    }

    /** Log out using REDIRECT flow. */
    async logOut() {
        if (this.msalInstance && this.isLoggedIn) {
            // No user signed in
            try {
                // NOTE: logout REDIRECT is used instead of POPUP to not allow the user to cancel out of the dialog and get the
                // application into a weird state where the user thinks they're logged out but they're not
                await this.msalInstance.logoutRedirect({
                    // postLogoutRedirectUri: "./loggedOut.html",
                    // mainWindowRedirectUri: LOGOUT_REDIRECT_URI,
                    postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
                });
            }
            catch (err) {
                console.error(err);
                if ('errorCode' in err && (err.errorCode.includes('popup_window_error') || err.errorCode.includes('user_cancelled'))) {
                    throw new Error(POPUPS_BLOCKED_ERROR);
                }
                else throw err;
            }
        }

        this.isLoggedIn = false;
    }

    private async getAccessToken(): Promise<string> {
        if (this.msalInstance === null) {
            throw new Error(`No MSAL instance for ${this.appName} -- log in before trying to get an access token!`);
        }

        // throw if an active account has not been set
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account has been set');
        }

        let response: AuthenticationResult | undefined = undefined;
        let interactionRequired = false;

        // try to get the token silently, but fall back to popup window if it fails
        //
        // NOTE/TODO: asynchronous parallel operations (such as sending dicoms for auto-contouring)
        // may cause problems if the current access token (inside authInstance) has timed out and
        // msal has to fall back to acquireTokenPopup as this may cause parallel popup
        // windows which in turn will cause them to cancel each other. If this turns out to
        // be an actual problem then this code must be changed so that in case of a stale
        // token the first token/auth call/api operation must be blocking.
        //
        // see also: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
        try {
            response = await this.msalInstance.acquireTokenSilent({ ...this.request});
            if (!response) {
                interactionRequired = true;
            } else {
                this.verboseLog('Got an access token silently');
            }
        } catch (err) {
            console.error(err);
            interactionRequired = true;
        }

        if (interactionRequired) {
            this.verboseLog('Getting token silently failed -- trying through popup');
            response = await this.msalInstance.acquireTokenPopup({ redirectUri: POPUP_REDIRECT_URI, ...this.request });

            // change currently active user if needed
            if (response && response.account && response.account.homeAccountId) {
                const currentAccount = this.msalInstance.getActiveAccount();
                if (currentAccount && currentAccount.homeAccountId !== response.account.homeAccountId) {
                    this.verboseLog('Current actually active account has changed');
                    const nextAccount = this.msalInstance.getAccountByHomeId(response.account.homeAccountId);
                    if (!nextAccount) {
                        throw new Error('Not properly authenticated with expected account');
                    }

                    this.verboseLog('Setting new active account');
                    this.msalInstance.setActiveAccount(nextAccount);
                    this.setLatestActiveAccount();
                }
            }
        }

        if (!response) {
            throw new Error('Could not get access token');
        }

        return response.accessToken as string;
    }

    getLoggedInUserDetails(): LoggedInMsalUser | undefined {
        if (!this.msalInstance) { return undefined; }

        const account = this.msalInstance.getActiveAccount();
        if (!account) { return undefined; }

        return {
            userId: account.localAccountId,
            email: account.username,
            // name: account.name
            name: account.idTokenClaims ? account.idTokenClaims.preferred_username ? account.idTokenClaims.preferred_username : account.name : account.name
        };
    }

    async fetch(url: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {

        const backendOptions = new BackendFetchOptions(backendFetchOptions);

        if (!this.isLoggedIn) {
            throw new Error(`Must be logged into ${this.appName} before calling any MSAL APIs!`);
        }

        const token = await this.getAccessToken();

        const fetchOptions = httpRequestOptions || {};
        const bearer = `Bearer ${token}`;
        _.set(fetchOptions, 'headers.Authorization', bearer);

        if (!backendOptions.allowCache) {
            _.set(fetchOptions, 'headers.Cache-Control', 'no-store');
            _.set(fetchOptions, 'cache', 'no-store');
            _.set(fetchOptions, 'headers.pragma', 'no-cache');
        }

        if (backendOptions.fetchTimeoutInMs !== undefined) {
            _.set(fetchOptions, 'signal', timeoutSignal(backendOptions.fetchTimeoutInMs));
        }

        // set app version so backend knows which app and which version of it is sending the request
        _.set(fetchOptions, 'headers.appVersion', `${getAppName()}/${RTViewerDisplayVersion}/${this.appVersion ? this.appVersion.commit : 'N/A'}`);

        const fullUrl = new URL(url);

        if (!backendOptions.noClientId) {
            // append client ID to query parameters
            const clientIdQueryParam = `${QUERY_PARAM_CLIENT_ID}=${getClientId()}`;
            fullUrl.search = fullUrl.search ? `${fullUrl.search}&${clientIdQueryParam}` : clientIdQueryParam;
        }

        // let retrying = true;
        let currentRetry = 0;
        while (true) {
            try {
                return await fetch(fullUrl.toString(), fetchOptions);
            } catch (err) {
                console.log(`An error occurred when trying to fetch from ${url}`);
                console.log('Fetch options:');
                console.log(fetchOptions);
                console.log('Fetch error:');
                console.log(err);

                if (backendOptions.maxRetries > currentRetry) {
                    await sleep(backendOptions.retryWaitInMs);
                    currentRetry++;
                    console.warn(`Attempting retry ${currentRetry}/${backendOptions.maxRetries} to ${url}`)
                } else {
                    if (fetchOptions && _.get(fetchOptions, 'signal.reason.name', undefined) === 'TimeoutError') {
                        throw new Error('Request timed out.');
                    }

                    throw err;
                }
            }
        }
    }
}
