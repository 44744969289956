import { BackendTier } from "../web-apis/backends";
import _ from "lodash";

export type DeploymentConfigInfo = {
    rtViewerBackendUrl: string;
    rtViewerBackendTier: BackendTier;
    rtViewerBackendName?: string;
    regionInfo?: string;
    supportContactInformation?: string;
    allowMissingDicomMetaheaderFix: boolean;
    defaultReferenceLibraryStorageAccount?: string;
}

/** Default values for "non-optional" optional settings. */
const defaultOptions = {
    allowMissingDicomMetaheaderFix: true,
};

export const generateDeploymentConfigInfo = (jsonObject: any): DeploymentConfigInfo | undefined => {
    if ('rtViewerBackendUrl' in jsonObject && 'rtViewerBackendTier' in jsonObject && Object.values(BackendTier).includes(jsonObject['rtViewerBackendTier'])) {
        const deploymentConfigInfo: DeploymentConfigInfo = { rtViewerBackendUrl: jsonObject['rtViewerBackendUrl'], rtViewerBackendTier: jsonObject['rtViewerBackendTier'], ...defaultOptions }
        if ('rtViewerBackendName' in jsonObject) {
            deploymentConfigInfo.rtViewerBackendName = jsonObject['rtViewerBackendName'];
        }
        if ('regionInfo' in jsonObject) {
            deploymentConfigInfo.regionInfo = jsonObject['regionInfo'];
        }
        if ('supportContactInformationMarkdown' in jsonObject) {
            deploymentConfigInfo.supportContactInformation = jsonObject['supportContactInformationMarkdown'];
        }
        if ('allowMissingDicomMetaheaderFix' in jsonObject) {
            deploymentConfigInfo.allowMissingDicomMetaheaderFix = jsonObject['allowMissingDicomMetaheaderFix'];
        }
        if ('defaultReferenceLibraryStorageAccount' in jsonObject) {
            deploymentConfigInfo.defaultReferenceLibraryStorageAccount = jsonObject['defaultReferenceLibraryStorageAccount'];
        }
        return deploymentConfigInfo;
    }

    return undefined;
}
