import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import ModalDialog from "../ModalDialog";
import { produce } from "immer";


import './TasksDialog.css';
import SimilarityMetricsTable from "./roi-settings/SimilarityMetricsTable";
import { AzureShareInfo } from "../../../web-apis/azure-files";
import { TrainingTask, CalculatedMetrics } from "../../../datasets/training-task";
import _ from "lodash";
import { User } from "../../../store/user";
import ConfirmTaskOperationDialog, { ConfirmType } from "../../rtviewer/dialogs/ConfirmTaskOperationDialog";

type OwnProps = {
    isVisible: boolean,
    fileShare: AzureShareInfo,
}

type DispatchProps = {
    setTasksDialogVisibility(value: boolean): void,
    setCurrentTask(task: TrainingTask | undefined): void,
    updateTask: (task: TrainingTask, update: Partial<TrainingTask>) => void,
    deleteTask: (task: TrainingTask) => void,
    archiveTask: (task: TrainingTask) => void,
    onDuplicateTaskClick: () => void,
}

type OwnState = {
    task: TrainingTask | undefined,
    showConfirmDeletionDialog: boolean,
    showConfirmArchiveDialog: boolean,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class TasksDialog extends React.Component<AllProps, OwnState> {

    defaultState = {
        task: this.props.currentTask ? _.cloneDeep(this.props.currentTask) : undefined,
        showConfirmDeletionDialog: false,
        showConfirmArchiveDialog: false,
    };

    constructor(props: AllProps) {
        super(props);

        this.state = this.defaultState;
    }

    handleClose = () => {
        this.props.setTasksDialogVisibility(false);
        this.props.setCurrentTask(undefined);
    }

    handleCloseConfirmDialogs = () => {
        this.setState({ showConfirmDeletionDialog: false, showConfirmArchiveDialog: false });
    }

    handleChangeAcceptanceCriteria = (roiToUpdate: string, updates: Partial<CalculatedMetrics>) => {
        this.setState(produce((draft: OwnState) => {
            if (draft.task) {
                const matchingACRoiIndex = draft.task.gtStructureSet.rois.findIndex(r => r.roiName === roiToUpdate);
                if (matchingACRoiIndex === -1) {
                    throw new Error(`Could not find structure ${roiToUpdate} from acceptance criteria!`);
                }
                draft.task.gtStructureSet.rois[matchingACRoiIndex].acceptanceCriteria = Object.assign({}, { ...draft.task.gtStructureSet.rois[matchingACRoiIndex].acceptanceCriteria, ...updates });
            }
        }));
    }

    handleDescriptionChange = (option: any) => {
        if (this.state.task) {
            const description = option.currentTarget.value;
            this.setState({ task: { ...this.state.task, description: description } });
        }
    }

    handleStateChange = (option: any) => {
        if (this.state.task) {
            this.setState({ task: { ...this.state.task, state: option.value } });
        }
    }

    handleDeleteClick = (task: TrainingTask) => {
        this.setState({ showConfirmDeletionDialog: true, task: task });
    }

    handleArchiveClick = (task: TrainingTask) => {
        this.setState({ showConfirmArchiveDialog: true, task: task });
    }

    handleUpdateClick = () => {
        const originalTask = this.props.currentTask;
        const modifiedTask = this.state.task;

        if (originalTask && modifiedTask && (this.props.user as User).permissions.isSupervisor) {
            this.props.updateTask(originalTask, modifiedTask);
        }
        // shiw a notification using the Notification component that the task was updated
        //this.props.setNotificationMessage("Task updated successfully");
        this.handleClose();
    }

    handleDuplicateClick = () => {
        this.props.onDuplicateTaskClick();

        // close this dialog without clearing current task from redux store
        this.props.setTasksDialogVisibility(false);
    }

    render() {
        const isVisible = this.props.isUpdateTaskDialogVisible;
        const showDeleteTaskDialog = this.state.showConfirmDeletionDialog;
        const showArchiveTaskDialog = this.state.showConfirmArchiveDialog;
        const currentTask = this.props.currentTask;

        if (!currentTask) {
            return null;
        }

        return (
            <>
                <ModalDialog
                    show={isVisible}
                    onHide={this.handleClose}
                    size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title>Task Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-settings">
                        <div>Modifying the task assigned to Resident: {currentTask.trainee.user_name}</div>
                        <br />

                        <Form>
                            <Form.Group>
                                <Form.Row>
                                    <Col lg={6}>
                                        <div>Name of the task</div>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={60}
                                            readOnly
                                            defaultValue={currentTask.name}
                                            data-cy="task-modal-name"
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Row>
                                    <Col lg={6}>
                                        <div>Description of the task</div>
                                    </Col>
                                    <Col>
                                        <textarea
                                            className="form-control"
                                            defaultValue={currentTask.description}
                                            onChange={this.handleDescriptionChange}
                                            data-cy="task-modal-descr"
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <hr />

                            <Form.Group>
                                <Modal.Title>Test Acceptance Criteria</Modal.Title>
                                <div>Set acceptance criteria for Structures in a Test: {currentTask.name}</div>
                                <br />
                                {(this.props.currentTask && this.props.currentTask.gtStructureSet.rois) && (
                                    <SimilarityMetricsTable
                                        taskAcceptanceCriteria={this.props.currentTask.gtStructureSet.rois}
                                        changeAcceptanceCriteria={this.handleChangeAcceptanceCriteria}
                                        task={this.props.currentTask}
                                    />
                                )}

                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="success" onClick={this.handleUpdateClick} type="submit" className="m-right" data-cy="update-task-btn">Update Task</Button>
                        <Button variant="success" onClick={this.handleDuplicateClick} type="submit" className="m-right" data-cy="duplicate-task-btn">Duplicate Task</Button>
                        <Button data-cy="archive-task-btn" variant="info" onClick={() => this.handleArchiveClick(currentTask)}>{currentTask.archived === true ? "Unarchive Task" : "Archive Task"}</Button>
                        <Button data-cy="delete-task-btn" variant="danger" onClick={() => this.handleDeleteClick(currentTask)}>Delete Task</Button>
                        <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>

                    </Modal.Footer>

                </ModalDialog>

                <ConfirmTaskOperationDialog isVisible={showArchiveTaskDialog} onClose={this.handleCloseConfirmDialogs} currentTask={currentTask} handleTaskAction={() => this.props.archiveTask(currentTask)} onOperationSucceeded={this.handleClose} confirmType={ConfirmType.ARCHIVE} />
                <ConfirmTaskOperationDialog isVisible={showDeleteTaskDialog} onClose={this.handleCloseConfirmDialogs} currentTask={currentTask} handleTaskAction={() => this.props.deleteTask(currentTask)} onOperationSucceeded={this.handleClose} confirmType={ConfirmType.DELETE} />
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(TasksDialog);
