import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';

import * as sagas from '../../store/sagas';
import DatasetBrowser, { DatasetBrowserType } from '../annotation-page/DatasetBrowser';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';
//import GlobalToolbar from '../common/GlobalToolbar';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import WorkState, { Workspace } from '../../store/work-state';
import { DownloadTask } from '../../store/file-transfer-task';
import SplashScreen from '../splash-screen/SplashScreen';

import './ReferencePage.css';
import { NotificationType, SessionNotification } from '../common/models/SessionNotification';
import { User } from '../../store/user';
import { getScanId } from '../../store/scans';
import { UrlQuery, UrlQueryType } from '../../store/url-query';
import AccessForbidden from '../common/AccessForbidden';
import routePaths from '../../routes';

type OwnProps = {
    isSupportingScan?: boolean,
}

type DispatchProps = {
    setCurrentWorkState(workState: WorkState | null): void,
    loadAnnotationQuery(query: UrlQuery, workspace: Workspace): void,
    addNotification(notification: SessionNotification, delayInMilliseconds?: number): void,
    reloadDatasetGradings(dataset: Dataset): void,
    setIsAutoloading(isAutoloading: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps & RouteComponentProps;

type OwnState = {
    user?: string,
    datasetImage: DatasetImage | null,
    canEdit: boolean,
    isAutoLoading: boolean,
    autoLoadProgress?: number,
    isInvalidPage: boolean,
}

class ReferencePage extends React.Component<AllProps, OwnState> {
    displayName = ReferencePage.name

    constructor(props: AllProps) {
        super(props);

        let isAutoLoading = false;
        let isInvalidPage = false;

        try {
            // TODO: implement also on AnnotationPage

            const query = this.props.urlQuery;
            if (query) {
                // allow auto-loading if we're in reference library, OR we're in a VALID supporting scan URL
                if ((!this.props.isSupportingScan && query.isValid(UrlQueryType.ReferenceLibraryIndexQuery))
                    || (this.props.isSupportingScan && query.isValid(UrlQueryType.SupportingScanQuery))) {
                    isAutoLoading = true;
                    this.autoload(query);
                } else if (this.props.isSupportingScan) {
                    isInvalidPage = true;
                }
            } else {
                // reset an invalid query parameter at load at this point
                this.setUrl();
            }
        }
        catch (err) {
            const errorMessage = 'An error occurred when trying to initialize page.';
            this.props.setCurrentWorkState(new WorkState(null, null, false, Workspace.ReferenceLibrary, false, null, err.message || errorMessage));
            this.props.addNotification(new SessionNotification(Date.now().toString(), errorMessage, NotificationType.Error, err.message || undefined));
        }

        this.state = { canEdit: false, datasetImage: null, isAutoLoading: isAutoLoading, isInvalidPage: isInvalidPage, };
    }

    componentDidUpdate = (prevProps: AllProps) => {
        // update URL if current work state has changed, unless the work state is in an error state
        if (this.props.currentWorkState !== prevProps.currentWorkState && this.props.currentWorkState && !this.props.currentWorkState.hasError()) {
            this.setUrl();
        }

        // update autoload download progress & open RTViewer if auto-loading has finished
        const workState = this.props.currentWorkState;
        if (this.state.isAutoLoading && workState && workState.hasAnnotationWork() && workState.datasetImage) {
            const downloadKey = workState.datasetImage.downloadKey;
            const download: DownloadTask = this.props.downloads![downloadKey];

            if (download && download.ready) {
                this.finalizeAutoLoad(workState);
            }
        }
    }

    /**
     * Set current work state into URL's query (search) parameters,
     * or remove any existing query parameters if there is no current
     * work.
     */
    setUrl = () => {
        // currently only set URL based on current work state, whether it's
        // a scan loaded into rtviewer or a dataset listing opened on the
        // annotation page (this component)
        const workState = this.props.currentWorkState;
        if (workState) {
            const query = workState.getAnnotationQuery();
            if (query) {
                this.props.history.push({ search: query.getQueryParameters() });
                return;
            }
        }
    }

    autoload = (annotationQuery: UrlQuery) => {
        // put autoload flag into redux store to hide the sidebar during loading a reference scan
        // (but not when loading reference index, those load so much faster)
        if (annotationQuery.isValid(UrlQueryType.AnnotationWorkQuery)) {
            this.props.setIsAutoloading(true);
        }

        const isSupportingScan = window.location.pathname.includes(routePaths.supportingScan);
        this.props.loadAnnotationQuery(annotationQuery, isSupportingScan ? Workspace.SupportingScan : Workspace.ReferenceLibrary);
    }

    /**
     * Returns current progress (0-100) of autoload download, or undefined
     * if not relevant.
     */
    getAutoLoadProgress = (): number | undefined => {
        const workState = this.props.currentWorkState;
        if (workState && workState.hasAnnotationWork()) {
            const downloadKey = workState.datasetImage!.downloadKey;
            const download: DownloadTask = this.props.downloads![downloadKey];

            if (download) {
                return parseInt(download.progressPercentage.toString(), 10);
            }
        }

        return undefined;
    }

    /**
     * Validates that given workstate looks ok for annotation work. Returns a new, fixed workstate
     * if not, or null if the workstate was ok.
     */
    validateWorkState = (workState: WorkState): WorkState | null => {
        if (workState.canEdit && workState.datasetImage!.seriesId.length > 64) {
            alert("Image's SeriesInstanceUID length exceeds 64 characters. Editing is disabled!");
            return new WorkState(workState.datasetImage, workState.dataset, false, Workspace.ReferenceLibrary, false);
        }

        return null;
    }

    handleViewImage = (datasetImage: DatasetImage, dataset: Dataset) => {
        const workState = new WorkState(datasetImage, dataset, false, Workspace.ReferenceLibrary, false);
        const fixedWorkState = this.validateWorkState(workState);

        // change current work state & reload dataset gradings when we're changing the image we're viewing
        this.props.setCurrentWorkState(fixedWorkState ? fixedWorkState : workState);
        this.props.reloadDatasetGradings(dataset);

        this.viewImage(workState);
    }

    finalizeAutoLoad = (workState: WorkState) => {
        this.props.setIsAutoloading(false);
        const fixedWorkState = this.validateWorkState(workState);
        if (fixedWorkState) {
            this.props.setCurrentWorkState(fixedWorkState);
        }

        this.viewImage(fixedWorkState ? fixedWorkState : workState);
    }

    viewImage = (workState: WorkState) => {
        this.setState({ datasetImage: workState.datasetImage, canEdit: false, isAutoLoading: false, autoLoadProgress: undefined });
    }

    handleBack = () => {
        this.props.setCurrentWorkState(null);
    }

    render = () => {
        const { currentWorkState, user, isSupportingScan }: { currentWorkState?: WorkState, user?: User, isSupportingScan?: boolean } = this.props;

        // block access if user data is not loaded in, or if neither condition is true:
        //  * user is a supervisor, OR
        //  * user is a trainee and current page is in supporting scan mode
        if (this.state.isInvalidPage || !user || !(user.permissions.isSupervisor || (user.permissions.isTrainee && isSupportingScan))) {
            setTimeout(() => {
                this.props.history.push('/');
                window.location.reload();
            }, 3500);
            return <AccessForbidden />;
        }

        const { isAutoLoading } = this.state;
        const datasetImage = currentWorkState ? currentWorkState.datasetImage : null;
        const isImageMetadataLoaded = datasetImage !== null;
        const hasErrored = currentWorkState ? currentWorkState.hasError() : false;

        // automatically navigate datasetbrowser to the dataset from current work
        // state if we're coming in from a direct URL (i.e. we're auto-loading)
        const navigateToWorkState = isAutoLoading ? currentWorkState : undefined;

        // show splash screen if we're auto-loading a scan (and not a dataset page index)
        let showSplashScreen = !hasErrored && isAutoLoading && currentWorkState && !currentWorkState.isDatasetIndex;

        // show rtviewer if we're done with auto-loading and the splash screen (if applicable) 
        // and related scan dataset data is loaded in
        const showRTViewer = !showSplashScreen && !hasErrored && isImageMetadataLoaded && !isAutoLoading;

        // override showSplashScreen if we shouldn't show dataset browser
        if (isSupportingScan) {
            showSplashScreen = !showRTViewer;
        }

        // always default to showing the dataset browser if we've errored (or also in other fall-back cases)
        // TODO: will this cause problems if we set work state to error while already IN rtviewer?
        const showDatasetBrowser = !showSplashScreen && !showRTViewer;

        let canCreateRtstruct = currentWorkState ? currentWorkState.canCreateRtstruct : false;
        let scanId = isImageMetadataLoaded && datasetImage ? getScanId(datasetImage) : '';

        // TODO: is this a relevant consideration? Does NOT work as-is with new scan indexing approach
        // if (scanId.length > 64) {
        //     canCreateRtstruct = false;
        //     scanId = scanId.substring(0, 64);
        // }

        const currentWorkName = currentWorkState ? (this.props.currentWorkState as WorkState).getWorkName() : undefined;

        return (
            <>
                <div className="container-fluid annotation-container">

                    {showSplashScreen && (
                        <SplashScreen
                            isVisible={true}
                            showProgress={true}
                            progressNow={this.getAutoLoadProgress()}
                            splashText={`Loading ${isSupportingScan ? 'supporting scan' : 'reference scan'}${currentWorkName ? ` ${currentWorkName.substring(0, 32)}` : ''}...`}
                        />
                    )}

                    {!this.props.isSupportingScan && (<div className={showDatasetBrowser ? 'visible' : 'invisible'} >
                        <Row>
                            <Col>

                                <Row style={{ marginLeft: '0.1rem' }}><img src={"/img/guide-title-new.svg"} alt="logo" className="guide-logo" /><h4 className="header-title">Reference Library</h4></Row>
                                <DatasetBrowser
                                    type={DatasetBrowserType.ReferenceLibraryBrowser}
                                    viewImage={this.handleViewImage} isVisible={!isImageMetadataLoaded} initialWorkState={navigateToWorkState} history={this.props.history} location={this.props.location} match={this.props.match}
                                />
                            </Col>
                        </Row>
                    </div>)}


                    {showRTViewer ?
                        <RTViewer
                            scanId={scanId}
                            isSupportingScanPage={isSupportingScan}
                            datasetImage={datasetImage!}
                            canEdit={false}
                            canCreateRtstruct={isSupportingScan ? false : canCreateRtstruct}
                            handleBack={this.handleBack}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match} />
                        : null}
                </div>

                {!showRTViewer &&
                    <div className="footer-apps">Guide is part of MVision AI GBS™</div>}
            </>
        );
    }
};

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ReferencePage);
